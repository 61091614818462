import { GtmEvent } from '@adg/catalog/src/modules/Ecom/gtmEvents'
import { UIConfig } from '@adg/catalog/src/common/UIConfig'
import { UrlParams } from '@adg/catalog/src/common/utils'
import { SpinnerConfig } from '@/components/SpinnerConfig'
import { Catalog, ConfigItems, FeedItem, OrderSchedule, Upgrade } from '@adg/catalog/src/modules/Catalog'
import { IShopper, SavedState, ServiceabilityState, Validation } from '@adg/catalog/src/modules/Shopper'
import { Cart } from '@adg/catalog/src/modules/Order'
import { StoreOrder } from '@adg/catalog/src/modules/Order'
import { Timeslot } from '@adg/catalog/src/common/AutomationSchedule'
import { ADGPaymentInitializerResponse } from '@adg/catalog/src/common/Payments'

export interface State extends SavedState {
  shopper: IShopper
  cart: Cart
  cartItems: FeedItem[]
  retrievedShopper: IShopper
  order: StoreOrder
  scheduleOptions: Timeslot[][] | undefined
  config: UIConfig
  catalog: Catalog | undefined
  mobileCart: boolean
  showBanner: boolean
  showSpinner: SpinnerConfig
  spinners: Spinner[]
  spinnerUpdateTime: number
  gaEvents: GtmEvent[]
  incomingLob: string | undefined
  referrer: string | undefined
  UrlParams?: UrlParams
  debugMode: boolean
  selectedProductTypes: string[]
  idImage: string
  shopperMetricSent: boolean
  paymentInitializer: ADGPaymentInitializerResponse | null
  labelsGenerated?: Record<string, string>[]
  log?: string[]
}

export interface Spinner {
  id: string
  messages: string[]
  spinnerUpdateTime: number
  rank: number
}

export function getStateToSave(state: State): SavedState {
  return {
    currentStep: state.currentStep,
    serviceability: state.serviceability,
    availablePackageUpgrades: state.availablePackageUpgrades,
    schedule: state.schedule,
    validation: state.validation,
    configItems: state.configItems
  }
}
