<template>
  <v-container class="mt-12">
    <v-row no-gutters class="text-center">
      <template v-if="matchedAddress && isResidential">
        <v-col id="active-address-title" class="headline" cols="12">
          {{ activeAddressTitle }}
        </v-col>
        <v-col id="active-account-subtitle" class="title" cols="12">
          {{ activeAddressSubtitle }}
        </v-col>
      </template>
      <v-col class="headline pb-4" cols="12" v-else-if="matchedAddress && !isResidential">
        The address you entered matches to a commercial / business address.
      </v-col>
    </v-row>
    <v-row justify="center" class="text-center">
      <v-col cols="12" class="col-md-4 pa-2" v-for="(q, i) in questions" :key="q.question">
        <ServiceabilityQuestion
          :id="'question' + i"
          :question="q"
          :color="colors[i]"
          @answerToQuestion="answerQuestion"
          @questionValue="questionValue"
          :residential="isResidential"
        />
      </v-col>
    </v-row>
    <v-row dense justify="center">
      <v-col align-self="center">
        <StepperButtons :backAction="returnToStart" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, computed } from '@vue/composition-api'
import ServiceabilityQuestion from '@/components/serviceability/ServiceabilityQuestion.vue'
import useServiceability from '@/components/serviceability/useServiceability'
import { GET_MATCHED_ADDRESS, SET_ADDRESS_VERIFIED } from '@/store/types'
import { FullAddress } from '@adg/catalog/src/modules/Address'
import { IShopper } from '@adg/catalog/src/modules/Shopper'
import StepperButtons from '../order/StepperButtons.vue'
import { bus } from '@/main'
import $store from '@/store'
import useAutomationState from '@/store/useAutomationState'
import { getConfigBoolean, getConfigItem } from './../shared/getConfigItem'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import { UIConfig } from '@adg/catalog/src/common/UIConfig'

export default defineComponent({
  name: 'ServiceabilityQuestions',
  components: {
    ServiceabilityQuestion,
    StepperButtons
  },
  setup(props, { root }) {
    const { $router } = root as any
    const companyName = computed((): string => $store.getters.getUIConfig.companyName)
    const uiConfig = computed((): UIConfig => $store.getters.getUIConfig)
    const matchedAddress = computed((): FullAddress => $store.getters[GET_MATCHED_ADDRESS])
    const shopper = computed((): IShopper => $store.getters.getShopper)
    const isResidential: boolean = shopper.value?.tags?.classification === 'RESIDENTIAL'
    const { getServiceabilityQuestions } = useServiceability()
    const questions = getServiceabilityQuestions(matchedAddress.value, isResidential, uiConfig.value)
    const { cancelAutomation } = useAutomationState($store)
    const automateActiveAccounts = getConfigBoolean(ConfigKeys.automateActiveAccounts) ?? false
    const automateCommercialAccounts = getConfigBoolean(ConfigKeys.automateCommercialAccounts) ?? false
    const activeAddressTitle = computed(
      () => getConfigItem(ConfigKeys.activeAddressTitle) ?? 'We found an active account at that address.'
    )
    const activeAddressSubtitle = computed(
      () => getConfigItem(ConfigKeys.activeAddressSubtitle) ?? 'Please tell us more about yourself.'
    )

    const answerQuestion = () => {
      $store.commit(SET_ADDRESS_VERIFIED, true)
      if (isResidential && !automateActiveAccounts) {
        cancelAutomation('Active Account')
      } else if (!isResidential && !automateCommercialAccounts) {
        cancelAutomation('Commercial Address')
      }
      $router.push({ path: `/order/2` })
    }

    const questionValue = (value) => {
      $store.dispatch('updateServiceabilityChoice', value)
    }

    const defaultColors = ['secondary', 'primary', 'secondaryDark']

    const colors = computed(() => questions.map((element, i) => element.headerColor ?? defaultColors[i]))

    const returnToStart = () => {
      bus.$emit('resetApp')
    }

    return {
      answerQuestion,
      companyName,
      questions,
      matchedAddress,
      colors,
      isResidential,
      questionValue,
      returnToStart,
      activeAddressTitle,
      activeAddressSubtitle
    }
  }
})
</script>

<style></style>
