
<v-list-item>
  <v-list-item-content>
    <v-list-item-title
      ><span v-html="uiMacroParser(shopper, itemDisplayName(item))"></span><span><Tooltip :payload="item" /></span
    ></v-list-item-title>
    <v-list-item-subtitle v-if="getProductSubtitle(item)">{{ getProductSubtitle(item) }}</v-list-item-subtitle>
    <v-list-item-subtitle class="pt-6" style="overflow: visible">
      <v-slider
        class="ma-0"
        :class="getCatalogItemClassName(item.Name, item.itemType)"
        v-model="model"
        color="secondary"
        track-color="grey"
        always-dirty
        :min="min"
        :max="max"
        @change="changed"
        thumb-size="20"
        :thumb-label="thumbLabel"
        ticks
        style="max-width: 275px"
      >
        <template v-slot:prepend>
          <v-icon color="secondaryDark" @click="decrement">
            {{ mdiMinus }}
          </v-icon>
        </template>

        <template v-slot:append>
          <v-icon color="secondaryDark" @click="increment">
            {{ mdiPlus }}
          </v-icon>
        </template> </v-slider
      ><TooltipDebug :item="item" />
    </v-list-item-subtitle>
  </v-list-item-content>
  <v-list-item-action>{{ coreCurrency(getCalcPrice(item)) }}</v-list-item-action>
</v-list-item>
