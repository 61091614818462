import { GET_SHOW_SPINNER } from '@/store/types'
import { UIConfig } from '@adg/catalog/src/common/UIConfig'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import { computed } from '@vue/composition-api'
import { getConfigItem } from './shared/getConfigItem'
import { checkConditional } from '@adg/catalog/src/common/utils'

export default ($store) => {
  const uiConfig = computed(() => $store.getters.getUIConfig as UIConfig)

  const footerColor = computed(() => getConfigItem(ConfigKeys.footerColor) ?? 'black')

  const shopper = computed(() => $store.getters.getShopper)

  const links = computed(() => {
    const rawLinks = uiConfig.value.footerLinks ?? uiConfig.value['footer-links'] ?? []
    return rawLinks.filter((link) => {
      if (!link.conditions) return true
      return link.conditions.some((condition) => {
        if (Array.isArray(condition)) {
          return checkConditional(condition, shopper.value)
        } else {
          return checkConditional([condition], shopper.value)
        }
      })
    })
  })

  const socialMediaImages = computed(() => uiConfig.value.socialMediaImages ?? [])

  const footerComponent = computed(() => getConfigItem(ConfigKeys.footerComponent) ?? 'AppFooter')

  const footerTopText = computed(() => getConfigItem(ConfigKeys.footerTopText))

  const footerLogo = computed(() => getConfigItem(ConfigKeys.footerIcon) ?? '/api/content/footerLogo.png')

  return {
    uiConfig,
    footerColor,
    links,
    footerComponent,
    footerTopText,
    footerLogo,
    socialMediaImages
  }
}
