<template>
  <div>
    <v-container style="max-width: 1200px" class="schedule-page px-sm-12" v-if="currentStep > 4">
      <v-row>
        <v-col v-if="schedulePageSubHeader" class="schedule-sub-header" md="7" cols="12">
          <SubHeader
            :icon="schedulePageSubHeader.icon"
            :largeText="schedulePageSubHeader.largeText"
            :smallText="schedulePageSubHeader.smallText"
          >
          </SubHeader>
        </v-col>
        <v-col md="7" cols="12" class="py-0 px-5 installation-options">
          <div v-if="canSelfInstall">
            <CustomScheduling />
          </div>
          <div class="fields" v-else>
            <v-card :tile="sharpCorners" class="schedule" width="700">
              <v-toolbar flat id="account-header" height="40" dark :color="scheduleInfoHeaderColor">
                <v-toolbar-title id="installation-options-header-text">
                  <v-icon v-if="showInstallationOptionsWrenchIcon" style="padding-right: 6px" :color="wrenchIconColor">
                    {{ mdiWrenchOutline }}
                  </v-icon>
                  <span id="installation-header-text" v-html="installationOptionsHeaderText"></span>
                </v-toolbar-title>
              </v-toolbar>
              <v-form v-model="validated">
                <v-container fluid>
                  <v-row v-if="scheduleCardHeaderSection">
                    <v-col>
                      <div class="schedule-card-header">{{ scheduleCardHeaderSection.header }}</div>
                      <div class="schedule-card-subheader">{{ scheduleCardHeaderSection.subHeader }}</div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="headline primary--text" id="installation-options-address">{{ punctuatedAddress }}</v-col>
                  </v-row>
                  <div v-for="(item, i) in schedulingFields" :key="`schedulingField-${i}`">
                    <ScheduleOptions v-if="item.type === 'schedule'" />
                    <AccountFieldUI :field="item" v-else />
                  </div>
                  <PayContainer v-if="!steppersOutsideCards" :disabled="!validated" />
                </v-container>
              </v-form>
            </v-card>
          </div>
          <PayContainer v-if="steppersOutsideCards" :disabled="!validated" />
        </v-col>
        <v-col md="5" cols="12" class="py-0 hidden-sm-and-down">
          <Cart />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script lang="ts">
import Cart from '@/components/order/cart/Cart.vue'
import { computed, defineComponent, ref } from '@vue/composition-api'
import StepperButtons from '@/components/order/StepperButtons.vue'
import useUiConfig from '@/components/shared/useUiConfig'
import { getConfigItem } from '@/components/shared/getConfigItem'
import CustomScheduling from '@/components/order/scheduling/CustomScheduling.vue'
import NonAutomatedSchedulingOptions from '@/components/order/scheduling/NonAutomatedSchedulingOptions.vue'
import ScheduleOptions from '@/components/order/scheduling/ScheduleOptions.vue'
import PayContainer from '@/components/order/payment/PayContainer.vue'
import { mdiWrenchOutline } from '@mdi/js'
import $store from '@/store'
import { IShopper } from '@adg/catalog/src/modules/Shopper'
import AccountFieldUI from '@/components/order/account/AccountFieldUI.vue'
import { AccountField, Conditional } from '@adg/catalog/src/common/UIConfig'
import { getPunctuatedAddressFromTags } from '@adg/catalog/src/common/addressUtil'
import { usePiniaRoute } from '@/store/pinia/piniaRoute'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import { checkConditional } from '@adg/catalog/src/common/utils'
import { uiMacroParser } from '@/utils/ShopperHelpers'
import SubHeader from '@/components/layout/SubHeader.vue'

export default defineComponent({
  name: 'Schedule',
  components: {
    Cart,
    AccountFieldUI,
    StepperButtons,
    CustomScheduling,
    ScheduleOptions,
    PayContainer,
    NonAutomatedSchedulingOptions,
    SubHeader
  },
  setup(props, { emit }) {
    const currentStep = computed(() => usePiniaRoute().currentStep)

    const shopper = computed(() => $store.getters.getShopper as IShopper)
    const schedulePageSubHeader = computed(() => getConfigItem(ConfigKeys.schedulePageSubHeader) ?? undefined)
    const scheduleCardHeaderSection = computed(() => getConfigItem(ConfigKeys.scheduleCardHeaderSection) ?? undefined)
    const isAutomatedOrder = computed(() => $store.getters.getShopper?.automationState?.status === 'success')
    const canSelfInstall = computed(() => shopper.value.customInfo.canSelfInstall && isAutomatedOrder.value)
    const sharpCorners = computed(() => getConfigItem(ConfigKeys.cardSharpCorners) ?? false)
    const { scheduleInfoHeaderColor } = useUiConfig()
    const showInstallationOptionsWrenchIcon = computed(() => getConfigItem(ConfigKeys.showInstallationOptionsWrenchIcon) ?? false)
    const wrenchIconColor = computed(() => getConfigItem(ConfigKeys.wrenchIconColor))
    const schedulingCardHeaderTextMacros = computed(
      () =>
        (getConfigItem(ConfigKeys.schedulingCardHeaderTextMacros) as Array<{ macroString: string; conditions: Conditional[] }>) ??
        undefined
    )
    const steppersOutsideCards = computed(() => getConfigItem(ConfigKeys.steppersOutsideCards) ?? false)

    const installationOptionsHeaderText = computed(() => {
      let headerText = getConfigItem(ConfigKeys.installationOptionsHeaderText) ?? 'INSTALLATION OPTIONS'
      if (schedulingCardHeaderTextMacros.value) {
        schedulingCardHeaderTextMacros.value.forEach((macro) => {
          if (macro.conditions) {
            const conditionsMet = macro.conditions.some((macroCondition) => {
              //if multiple conditions grouped together, check each one. otherwise check the single condition.
              //if a single condition or group of conditions is true, set the header text
              if (Array.isArray(macroCondition)) {
                return checkConditional(macroCondition, shopper.value)
              } else {
                //check conditional takes array of conditions, if single condition, put it in an array.
                return checkConditional([macroCondition], shopper.value)
              }
            })
            if (conditionsMet) {
              headerText = uiMacroParser(shopper.value, macro.macroString, true)
            }
          } else {
            headerText = uiMacroParser(shopper.value, macro.macroString, true)
          }
        })
      }
      return headerText
    })

    const schedulingFields = computed(
      (): AccountField[] => getConfigItem(ConfigKeys.schedulingFields) ?? [{ type: 'schedule', label: '', defaultValue: '' }]
    )
    const punctuatedAddress = computed(() => getPunctuatedAddressFromTags($store.getters.getShopper))
    const validated = ref(true)
    return {
      scheduleInfoHeaderColor,
      canSelfInstall,
      installationOptionsHeaderText,
      showInstallationOptionsWrenchIcon,
      mdiWrenchOutline,
      sharpCorners,
      wrenchIconColor,
      schedulingFields,
      punctuatedAddress,
      currentStep,
      validated,
      schedulingCardHeaderTextMacros,
      schedulePageSubHeader,
      scheduleCardHeaderSection,
      steppersOutsideCards
    }
  }
})
</script>

<style>
.scheduleSubTitle {
  font-size: 18px;
  margin-left: 20px;
}

.timeRadio {
  margin-left: 0px;
}

.schedule {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
