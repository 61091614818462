<template>
  <v-container fluid class="error-container">
    <v-row justify="center">
      <v-card flat class="ma-2">
        <v-container>
          <v-row>
            <v-col v-if="errorPageHeader" cols="12" class="display-2 text-center" v-html="errorPageHeader"></v-col>
            <v-col v-else cols="12" class="display-2 text-center"> Looks like something went wrong. </v-col>
          </v-row>
          <v-row>
            <v-col v-if="errorPageText" cols="12" class="headline text-center" v-html="errorPageText"> </v-col>
            <v-col v-else cols="12" class="headline text-center">
              Rest assured we’re on the case and will be back up and running soon.
            </v-col>
          </v-row>
          <v-row>
            <v-col id="error-page-button" cols="12" class="text-center">
              <v-btn @click="startNew()">Try Again</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { computed, defineComponent, watch } from '@vue/composition-api'
import Header from '@/components/layout/Header.vue'
import { UIConfig } from '@adg/catalog/src/common/UIConfig'
import $store from '@/store'
import { getConfigString } from './shared/getConfigItem'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog/v4'
export default defineComponent({
  components: {
    Header
  },

  setup(props, context) {
    const uiConfig = computed(() => $store.getters.getUIConfig as UIConfig)
    const errorPageHeader = computed(() => getConfigString(ConfigKeys.errorPageHeader))
    const errorPageText = computed(() => getConfigString(ConfigKeys.errorPageText))

    const year = new Date().getFullYear()

    const { links } = useFooter($store)

    const startNew = () => (window.location.href = `${window.location.origin}`)

    const allowDebug = computed(() => $store.getters.allowDebug)
    const isDebug = computed({
      get: () => $store.getters.getDebugMode,
      set: (val) => $store.commit('setDebugMode', val)
    })
    const appState = computed(() => $store.getters.getState)

    return { links, year, uiConfig, startNew, allowDebug, isDebug, appState, errorPageHeader, errorPageText }
  }
})
</script>

<style>
.card-bottom-corners {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.withSystemBar {
  padding: 40px;
}
.noSystemBar {
  padding-top: 10px;
}
#error-page-button {
  font-weight: 500;
}
.error-container {
  display: flex;
  align-items: center;
  height: 100%;
}
</style>
