<template>
  <v-container class="pa-0">
    <v-row :class="$vuetify.breakpoint.xs ? ['pt-2', 'text-center'] : ''" dense v-if="packageUpgrades.length > 0">
      <v-col
        v-if="product['Product Type'] === 'Internet'"
        class="title primary--text internet-upgrade-header"
        id="customize-group-header"
        v-html="internetUpgradeText"
      ></v-col>
      <v-col
        v-if="product['Product Type'] === 'TV'"
        class="title primary--text tv-upgrade-header"
        id="customize-group-header"
        v-html="tvUpgradeText"
      ></v-col>
      <v-col
        v-if="product['Product Type'] === 'Phone'"
        class="title primary--text phone-upgrade-header"
        id="customize-group-header"
        v-html="phoneUpgradeText"
      ></v-col>
    </v-row>
    <v-row class="upgrade-row" v-for="pu in packageUpgrades" :key="pu.Package">
      <v-col cols="8">
        <v-list-item class="upgrade-list-item">
          <v-list-item-content class="pa-0">
            <v-list-item-title class="upgrade-display-name">
              {{ fullUpgradeText ? packageUpgradeText(pu) : pu.UpgradeText }}
              <Tooltip :payload="{ Name: pu.UpgradeText }" />
              <TooltipDebug :item="pu" :name="pu.Package" />
            </v-list-item-title>
            <v-list-item-subtitle v-if="pu.Subtitle" v-html="pu.Subtitle"></v-list-item-subtitle>
            <v-list-item-subtitle v-if="showPackageUpgradePriceDurationAndTerms"
              ><span
                ><div class="upgrade-price">{{ '$' + pu.monthlyPrice.toFixed(0) }}</div>
                <div class="upgrade-duration">/mo</div>
                <div class="upgrade-price-terms">Taxes & fees incl.</div>
              </span></v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="4" class="text-right pt-2 upgrade">
        <div v-if="packageUpgradeButtonTitle" class="package-upgrade-button-title">{{ packageUpgradeButtonTitle }}</div>
        <v-btn
          v-if="!useAlternativeButton"
          :small="$vuetify.breakpoint.xs"
          @click="upgradePackage(pu.Package)"
          :color="buttonColor(upgradeButtonColor)"
          :class="buttonTextColor()"
          v-bind="btnAttrs"
          id="upgrade-button"
          >{{ getUpgradeButtonText(pu.Price) }}
          <v-icon class="icon" v-if="upgradeButtonIcon">{{ upgradeButtonIcon }}</v-icon></v-btn
        >
        <v-btn
          width="100%"
          rounded
          v-if="useAlternativeButton"
          :small="$vuetify.breakpoint.xs"
          @click="upgradePackage(pu.Package)"
          color="yellow"
          :class="buttonTextColor()"
          v-bind="btnAttrs"
          id="alt-upgrade-button"
          >Select</v-btn
        >
      </v-col>
    </v-row>
    <hr class="card-divider" v-if="dividersInCards && packageUpgrades.length > 0" />
  </v-container>
</template>

<script lang="ts">
import { computed, defineComponent } from '@vue/composition-api'
import { bus } from '@/main'
import { GET_CATALOG, PACKAGE_UPGRADE, SET_PACKAGE, SUBCATEGORY } from '../../store/types'
import useButtonColors from '@/components/shared/useButtonColors'
import Tooltip from '@/components/shared/tooltip/Tooltip.vue'
import TooltipDebug from '@/components/shared/tooltip/TooltipDebug.vue'

import { ConfigKeys, Upgrade, getItemPrice } from '@adg/catalog/src/modules/Catalog'
import { getConfigItem } from './getConfigItem'
import $store from '@/store'
import PackageUpgrade from '@/interfaces/PackageUpgrade'
import { coreCurrency } from '@adg/catalog/src/common/filters'

export default defineComponent({
  name: 'PackageUpgrades',
  components: {
    Tooltip,
    TooltipDebug
  },
  props: {
    product: Object,
    upgradeTextColor: String
  },
  setup(props) {
    const getUpgradeButtonText = (price: string) => {
      if (!showPriceInPackageUpgradeButton.value) return upgradeButtonText.value ?? 'Select'
      if (showPriceInPackageUpgradeButton.value) {
        return (
          priceLabeledPackageUpgradeTextPrefix.value +
          parseInt(price).toFixed(0).toString() +
          priceLabeledPackageUpgradeTextSuffix.value
        )
      }
    }

    const displayNameInPackageUpgradeRow = computed(() => getConfigItem(ConfigKeys.displayNameInPackageUpgradeRow) ?? false)

    const priceLabeledPackageUpgradeTextPrefix = computed(
      () => getConfigItem(ConfigKeys.priceLabeledPackageUpgradeTextPrefix) ?? ''
    )

    const priceLabeledPackageUpgradeTextSuffix = computed(
      () => getConfigItem(ConfigKeys.priceLabeledPackageUpgradeTextSuffix) ?? ''
    )

    const showPackageUpgradePriceDurationAndTerms = computed(
      () => getConfigItem(ConfigKeys.showPackageUpgradePriceDurationAndTerms) ?? false
    )
    const packageUpgradeButtonTitle = computed(() => getConfigItem(ConfigKeys.packageUpgradeButtonTitle) ?? undefined)

    const useAlternativeButton = computed(() => getConfigItem(ConfigKeys.useAlternativeButton) ?? false)

    const hideUpgradePriceZeroes = computed(() => getConfigItem(ConfigKeys.hideUpgradePriceZeroes) ?? false)

    const upgradeButtonIcon = computed(() => getConfigItem(ConfigKeys.upgradeButtonIcon) ?? undefined)

    const upgradeButtonText = computed(() => getConfigItem(ConfigKeys.upgradeButtonText) ?? undefined)

    const packageUpgradePrefix = computed(() => getConfigItem(ConfigKeys.packageUpgradePrefix) ?? ' for only $')

    const packageUpgradePostfix = computed(() => getConfigItem(ConfigKeys.packageUpgradePostfix) ?? ' more per month')

    const packageUpgradeLessPrefix = computed(() => getConfigItem(ConfigKeys.packageUpgradeLessPrefix) ?? ' for $')

    const packageUpgradeLessPostfix = computed(() => getConfigItem(ConfigKeys.packageUpgradeLessPostfix) ?? ' less per month')

    const internetUpgradeText = computed(() => getConfigItem(ConfigKeys.internetUpgradeText) ?? 'Upgrade your Internet')

    const tvUpgradeText = computed(() => getConfigItem(ConfigKeys.tvUpgradeText) ?? 'Upgrade your TV')

    const phoneUpgradeText = computed(() => getConfigItem(ConfigKeys.phoneUpgradeText) ?? 'Upgrade your Phone')

    const selectedPackage = computed(() => $store.getters.getPackage)

    const dividersInCards = computed(() => getConfigItem(ConfigKeys.dividersInCards) ?? false)

    const upgradeProduct = computed(() =>
      selectedPackage.value.Products
        ? selectedPackage.value.Products.find((p) => p['Product Type'] === props.product?.['Product Type'])
        : undefined
    )

    const upgradeButtonColor = computed(() => getConfigItem(ConfigKeys.upgradeButtonColor) ?? 'secondary')

    const showPriceInPackageUpgradeButton = computed(() => getConfigItem(ConfigKeys.showPriceInPackageUpgradeButton) ?? false)
    const fullUpgradeText = getConfigItem(ConfigKeys.fullUpgradeText) ?? true

    // need to get selected package here because the Upgrades hang off of it.
    const packageUpgrades = computed(() => {
      const pUp = upgradeProduct.value
        ? upgradeProduct.value.Upgrades.filter((u: Upgrade) => u[SUBCATEGORY] === PACKAGE_UPGRADE)
        : []
      const reverseUpgradeOrder = getConfigItem(ConfigKeys.reverseUpgradeOrder)
      pUp.forEach((u: Upgrade) => {
        const pkg = $store.getters[GET_CATALOG].Packages.find((p) => p.Name === u.Package)
        u.monthlyPrice = getItemPrice(pkg, 'Monthly Price', 1)
      })
      return pUp.sort((a, b) => (reverseUpgradeOrder ? a.Value - b.Value : b.Value - a.Value))
    })

    const upgradePackage = (name: string) => {
      const pkg = $store.getters[GET_CATALOG].Packages.find((p) => p.Name === name)
      $store.commit(SET_PACKAGE, pkg)
      bus.$emit('packageChanged')
    }

    const removeUpgradePriceZeroes = (packageUpgrade: Upgrade) => {
      if (
        hideUpgradePriceZeroes.value &&
        packageUpgrade?.Price?.toString().substring(packageUpgrade.Price.toString().indexOf('.') + 1) === '00'
      ) {
        return true
      } else return false
    }
    //TODO: not sure how to fix line 138
    const packageUpgradeText = (packageUpgrade: Upgrade) => {
      const upgradePrice =
        packageUpgrade?.Price?.toString().substring(0, packageUpgrade?.Price?.toString().indexOf('.')) +
        (removeUpgradePriceZeroes(packageUpgrade)
          ? ''
          : packageUpgrade?.Price?.substring(packageUpgrade?.Price?.toString().indexOf('.') ?? ''))
      // functionality to have package display name in the upgrade row for demo
      if (displayNameInPackageUpgradeRow.value) {
        const displayName = $store.getters[GET_CATALOG].Packages.find((p) => p.Name === packageUpgrade.Package)['Display Name']
        return 'Upgrade to ' + displayName
      }

      if (parseInt(upgradePrice) < 0) {
        return packageUpgrade.UpgradeText + packageUpgradeLessPrefix.value + upgradePrice.slice(1) + packageUpgradeLessPostfix.value
      } else {
        return packageUpgrade.UpgradeText + packageUpgradePrefix.value + upgradePrice + packageUpgradePostfix.value
      }
    }

    const { defaultButtonColors, buttonColor, buttonTextColor } = useButtonColors()

    //TODO: what is retVal's type?
    const btnAttrs = computed(() => {
      const btnType = getConfigItem(ConfigKeys.upgradeBtnType) ?? 'contained'
      let retVal = {}
      if (btnType) {
        retVal[btnType] = true
      }
      return retVal
    })

    return {
      btnAttrs,
      buttonColor,
      buttonTextColor,
      defaultButtonColors,
      packageUpgrades,
      upgradePackage,
      upgradeButtonColor,
      useAlternativeButton,
      removeUpgradePriceZeroes,
      hideUpgradePriceZeroes,
      internetUpgradeText,
      tvUpgradeText,
      phoneUpgradeText,
      packageUpgradeText,
      packageUpgradePrefix,
      packageUpgradePostfix,
      packageUpgradeLessPrefix,
      packageUpgradeLessPostfix,
      upgradeButtonIcon,
      upgradeButtonText,
      dividersInCards,
      coreCurrency,
      showPriceInPackageUpgradeButton,
      fullUpgradeText,
      getUpgradeButtonText,
      priceLabeledPackageUpgradeTextPrefix,
      priceLabeledPackageUpgradeTextSuffix,
      showPackageUpgradePriceDurationAndTerms,
      packageUpgradeButtonTitle,
      displayNameInPackageUpgradeRow
    }
  }
})
</script>

<style scoped>
.upgrade-list-item {
  display: inline-block;
}

.v-list-item {
  margin: 0px;
  padding: 0px !important;
  min-height: 6px;
}
</style>
