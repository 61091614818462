<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title
        ><span v-html="uiMacroParser(shopper, itemDisplayName(item))"></span><span><Tooltip :payload="item" /></span
      ></v-list-item-title>
      <v-list-item-subtitle v-if="getProductSubtitle(item)">{{ getProductSubtitle(item) }}</v-list-item-subtitle>
      <v-list-item-subtitle class="pt-6" style="overflow: visible">
        <v-slider
          class="ma-0"
          :class="getCatalogItemClassName(item.Name, item.itemType)"
          v-model="model"
          color="secondary"
          track-color="grey"
          always-dirty
          :min="min"
          :max="max"
          @change="changed"
          thumb-size="20"
          :thumb-label="thumbLabel"
          ticks
          style="max-width: 275px"
        >
          <template v-slot:prepend>
            <v-icon color="secondaryDark" @click="decrement">
              {{ mdiMinus }}
            </v-icon>
          </template>

          <template v-slot:append>
            <v-icon color="secondaryDark" @click="increment">
              {{ mdiPlus }}
            </v-icon>
          </template> </v-slider
        ><TooltipDebug :item="item" />
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>{{ coreCurrency(getCalcPrice(item)) }}</v-list-item-action>
  </v-list-item>
</template>
<script lang="ts">
import { computed, defineComponent, ref } from '@vue/composition-api'
import { mdiInformation, mdiPlus, mdiMinus } from '@mdi/js'
import { getItemPrice, getItemPriceAsNumber, itemDisplayName } from '@adg/catalog/src/modules/Catalog'
import useUiConfig from './useUiConfig'
import $store from '@/store'
import Tooltip from '@/components/shared/tooltip/Tooltip.vue'
import TooltipDebug from '@/components/shared/tooltip/TooltipDebug.vue'
import { getCatalogItemClassName } from '@adg/catalog/src/common/utils'
import { coreCurrency } from '@adg/catalog/src/common/filters'
import { uiMacroParser } from '@/utils/ShopperHelpers'
import { IShopper } from '@adg/catalog/src/modules/Shopper'

export default defineComponent({
  name: 'ProductPickerLineItem',
  props: {
    subtitle: String,
    renderMe: Number,
    item: {},
    min: Number,
    max: Number,
    thumbLabel: {
      //type: Boolean | String,
      validator: (v) => v === 'always' || v === true || v === false,
      default: 'always'
    }
  },
  components: {
    TooltipDebug,
    Tooltip
  },
  setup(props, { emit }) {
    const { getProductSubtitle } = useUiConfig($store)
    const getCalcPrice = (item) => item.calculatedPrice ?? 0

    const model = computed({
      get: () => (props.item.qty ? props.item.qty : props.item.min ?? 0),
      set: (val) => val
    })
    const price = ref(0)

    const shopper = computed(() => $store.getters.getShopper as IShopper)

    const changed = (val) => {
      if (getItemPrice(props.item, 'Monthly Price')) {
        price.value = getItemPriceAsNumber(props.item, 'Monthly Price') * val
      }
      if (props.item.OTC) {
        price.value = val === 0 ? 0 : getItemPriceAsNumber(props.item, 'OTC') // This look WRONG   KWC
      }
      emit('input', {
        ...props.item,
        qty: val,
        calculatedPrice: price.value
      })
    }
    const increment = () => {
      if (model.value < props.max) {
        changed(model.value + 1)
      }
    }
    const decrement = () => {
      if (model.value > props.min) {
        changed(model.value - 1)
      }
    }

    return {
      model,
      changed,
      increment,
      decrement,
      mdiInformation,
      mdiPlus,
      mdiMinus,
      getCalcPrice,
      itemDisplayName,
      getProductSubtitle,
      getCatalogItemClassName,
      coreCurrency,
      uiMacroParser,
      shopper
    }
  }
})
</script>
<style></style>
