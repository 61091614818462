<template>
  <div id="appHeader">
    <v-system-bar app class="white--text" color="primary" height="40" v-if="determineIfBannerDisplayed">
      <v-spacer></v-spacer>
      <div class="pr-10" v-html="bannerText"></div>

      <v-btn
        color="secondary"
        outlined
        class="promo-button font-weight-bold hidden-md-and-up"
        @click="applyPromo"
        :small="$vuetify.breakpoint.smAndUp"
        :x-small="$vuetify.breakpoint.xsOnly"
      >
        <span class="white--text">CLICK TO APPLY TO CART</span>
      </v-btn>
      <v-spacer></v-spacer>
    </v-system-bar>

    <v-app-bar class="appBar" height="92" flat :color="bannerColor" :extension-height="mobileCart ? 40 : 0" app>
      <v-spacer v-if="$vuetify.breakpoint.xsOnly"></v-spacer>
      <a :href="logoLink">
        <img contain :src="headerLogo" id="logo" class="logo" alt="logo"></img>
      </a>
      <v-spacer v-if="allowDebug"></v-spacer>
      <div v-if="allowDebug" class="pt-4 pb-0 pl-3 pr-3" style="background-color: black">
        <v-switch v-model="debugMode" label="Debug Mode" color="red" dark />
      </div>
      <TooltipDebug :item="appState" :name="'State'" />

      <v-spacer></v-spacer>
      <div v-if="showTagsDebug">
        <TagsDebug :address="punctuatedAddress" />
      </div>
      <div v-else>
        <span
          class="header-address justify-end text-center hidden-xs-only"
          v-if="punctuatedAddress != ''"
          v-bind:style="{ color: addressTextColor }"
          v-html="punctuatedAddress"
        ></span>
      </div>
      <div v-if="customerServiceHeaderConfig" class="customer-service-header-link">
        <span
          ><div>
            {{ customerServiceHeaderConfig.prefix }}
            <a :href="customerServiceHeaderConfig.href" class="customer-service-link">{{ customerServiceHeaderConfig.linkText }}</a>
          </div>
        </span>
        <div v-if="customerServiceHeaderConfig.buttonText">
          <v-btn icon class="chat-button">
            <v-icon>{{ customerServiceHeaderConfig.icon ?? 'mdi-chat' }}</v-icon>
            <div class="chat-text">{{ customerServiceHeaderConfig.buttonText }}</div>
          </v-btn>
        </div>
      </div>
      <template v-slot:extension>
        <v-container id="mobile-cart" fluid class="pa-0" v-if="mobileCart">
          <v-row justify="center" class="secondary black--text text-center">
            <v-toolbar @click="dialog = true" :color="monthlyTotalHeaderColor">
              <v-spacer></v-spacer>
              <span class="white--text headline font-weight-bold monthly-total">Monthly Total ${{ monthlyTotal.toFixed(2) }}</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="dialog = true" class="text-none">
                <v-icon color="white">mdi-cart-check</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-row>
        </v-container>
      </template>
    </v-app-bar>

    <!--    DIALOG CART-->
    <v-row  justify="center">
      <v-dialog content-class="cart-dialog" overlay-color="black" overlay-opacity=".8" v-model="dialog" scrollable max-width="450px">
        <v-card-text style="height: 800px" class="pa-0 cart-dialog-text">
          <Cart @cartClicked="dialog = !dialog" />
        </v-card-text>
        <v-divider></v-divider>
      </v-dialog>
    </v-row>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUpdated, ref } from '@vue/composition-api'
import Cart from '@/components/order/cart/Cart.vue'
import useCart from '@/components/order/cart/useCart'
import useOrder from '@/components/order/useOrder'
import { ConfigKeys, Promo } from '@adg/catalog/src/modules/Catalog'
import { getConfigItem } from '../shared/getConfigItem'
import TooltipDebug from '@/components/shared/tooltip/TooltipDebug.vue'
import $store from '@/store'
import { usePiniaUIConfig } from '@/store/pinia/piniaUIConfig'
import { usePiniaShopper } from '@/store/pinia/piniaShopper'
import ga4 from '@/gtm/ga4'
import { getPunctuatedAddressFromTags, getInputAddressFromTags } from '@adg/catalog/src/common/addressUtil'

export default defineComponent({
  name: 'Header',
  components: {
    Cart,
    TooltipDebug
  },
  setup(props, { refs, root }) {
    const { $vuetify, $router } = root as any
    const piniaState = computed(() => ({ uiConfig: usePiniaUIConfig().$state, shopper: usePiniaShopper().shopper }))

    const appBarColor = computed(() => (bannerText.value ? 'primary' : 'white'))
    const { monthlyTotal } = useCart($store)
    const selectedPackage = computed(() => $store.getters.getPackage)
    const logoLink = computed(() => getConfigItem(ConfigKeys.logoLink))
    const headerLogo = computed(() => getConfigItem(ConfigKeys.headerLogo) ?? '/api/content/logo.png')
    const customerServiceHeaderConfig = computed(() => getConfigItem(ConfigKeys.customerServiceHeaderConfig) ?? false)

    const monthlyTotalHeaderColor = computed(() => getConfigItem(ConfigKeys.monthlyTotalHeaderColor) ?? 'secondary')

    const allowDebug = computed(() => $store.getters.allowDebug)
    const debugMode = computed({
      get: () => $store.getters.getDebugMode,
      set: (val) => $store.commit('setDebugMode', val)
    })

    const appState = computed(() => $store.getters.getState)
    const mobileCart = computed(() => $vuetify.breakpoint.smAndDown && $store.getters.getMobileCart)

    const { currentStep } = useOrder($store, $router)

    onUpdated(() => {
      if ($vuetify.breakpoint.mdAndUp) {
        dialog.value = false
      }
    })

    onMounted(() => {
      if (determineIfBannerDisplayed.value) {
        ga4.pushPromo('view_promotion', availablePromo.value, 'banner')
      }
    })

    const determineIfBannerDisplayed = computed(
      () =>
        showBanner.value &&
        bannerText.value &&
        (getConfigItem(ConfigKeys.promoStepsAllowed) ?? [1, 2, 3, 4]).includes(currentStep.value)
    )

    const availablePromo = computed(() => {
      if (selectedPackage && selectedPackage.value && selectedPackage.value.Promos && selectedPackage.value.Promos.length > 0) {
        let bestPromo = selectedPackage.value.Promos.filter((pro: Promo) => pro['Promo Type'] == 'Automatic') //look for promos with type as automatic
        if (bestPromo.length == 0) bestPromo = selectedPackage.value.Promos // if no automatic promos, use first promo in the array
        return bestPromo[0]
      }

      return undefined
    })

    const promo = computed(() => $store.getters.getPromo)

    const applyPromo = () => {
      $store.commit('setPromo', availablePromo.value)
      if (promo.value) {
        ga4.pushPromo('select_promotion', promo.value, 'banner')
      }
    }

    const bannerText = computed(() => {
      if (!availablePromo.value || promo.value) return undefined
      return $vuetify.breakpoint.smAndDown ? availablePromo.value['Mobile Description'] : availablePromo.value.Description
    })

    const inputAddress = computed(() => getInputAddressFromTags($store.getters.getShopper))
    const punctuatedAddress = computed(() => inputAddress.value ?? getPunctuatedAddressFromTags($store.getters.getShopper))

    const showBanner = computed({
      get: () => $store.getters.getShowBanner,
      set: (val) => $store.commit('setShowBanner', val)
    })

    const dialog = ref(false)

    const bannerColor = computed(() => getConfigItem(ConfigKeys.bannerColor) ?? '#FFFFFF')

    const addressTextColor = computed(() => (bannerColor.value === '#FFFFFF' ? '#000000' : '#FFFFFF'))

    const showTagsDebug = computed(() => debugMode.value || $store.getters.getUrlParams.showAddressTags === 'true')

    //console.log(bannerText.value)

    return {
      logoLink,
      monthlyTotal,
      selectedPackage,
      promo,
      applyPromo,
      punctuatedAddress,
      bannerText,
      determineIfBannerDisplayed,
      mobileCart,
      dialog,
      appBarColor,
      addressTextColor,
      bannerColor,
      headerLogo,
      allowDebug,
      debugMode,
      appState,
      monthlyTotalHeaderColor,
      piniaState,
      showTagsDebug,
      customerServiceHeaderConfig
    }
  }
})
</script>

<style>
#appHeader div.v-toolbar__content {
  padding: 5px;
}
#appHeader div.v-toolbar__extension {
  padding: 0px;
  background-color: white;
}
.logo {
  padding-left: 20px;
  width: auto;
  height: auto;
  max-width: min(200px, 100%);
  max-height: 80px;
}
</style>
