
<v-container style="max-width: 1200px" class="px-sm-12 account" :key="renderMe">
  <div v-if="true">
    <v-row class="py-0 px-2">
      <v-col class="account-sub-header" cols="12">
        <SubHeader
          v-if="accountPageSubHeader"
          :icon="accountPageSubHeader.icon"
          :largeText="accountPageSubHeader.largeText"
          :smallText="accountPageSubHeader.smallText"
        ></SubHeader>
      </v-col>
      <v-col class="col-md-7 col-sm-12">
        <v-form>
          <!-- new panel version -->
          <v-expansion-panels
            :tile="sharpCorners"
            v-model="selectedPanel"
            :class="accountInfoClass"
            :readonly="accountPanels.length === 1"
            id="accountPanels"
          >
            <v-expansion-panel v-for="(p, i) of accountPanels" :key="'panel-' + i" :id="'panel-' + i">
              <v-expansion-panel-header
                :hide-actions="accountPanels.length === 1"
                id="account-header"
                height="40"
                :color="accountInfoHeaderColor"
                disable-icon-rotate
              >
                <template v-slot:actions>
                  <div>
                    <span>
                      <v-icon />
                      <TooltipIcon
                        v-if="p.validated"
                        :accountInfoTooltipColor="accountInfoTooltipColor"
                        message="Completed"
                        :icon="mdiCheckCircleOutline"
                      />
                      <TooltipIcon
                        v-else
                        :accountInfoTooltipColor="accountInfoTooltipColor"
                        message="Required"
                        :icon="mdiAlertCircleOutline"
                      />
                    </span>
                    <span>
                      <v-icon />
                      <v-icon v-if="selectedPanel == i" color="black">{{ mdiMinusCircleOutline }}</v-icon>
                      <v-icon v-else color="black">{{ mdiPlusCircleOutline }}</v-icon>
                    </span>
                  </div>
                </template>
                <div>
                  <v-icon id="account-info-icon" v-if="showAccountInfoIcon" style="padding-right: 6px" color="#08131f">
                    {{ mdiAccountCircleOutline }}
                  </v-icon>
                  {{ p.title }}
                  <!-- <v-icon v-if="testValidation[i]" color="black">{{ mdiCheckCircleOutline }}</v-icon> -->
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content id="account-panel-content">
                <!-- only put address header on first exp panel -->
                <v-form v-model="p.validated">
                  <v-row v-if="i == 0">
                    <v-col id="account-address-text" cols="12" class="headline primary--text">
                      {{ address }}
                    </v-col>
                  </v-row>
                  <v-row v-if="accountCardHeaderSection">
                    <v-col>
                      <div class="account-card-header">{{ accountCardHeaderSection.header }}</div>
                      <div class="account-card-subheader">{{ accountCardHeaderSection.subHeader }}</div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-for="field of p.fields"
                      v-show="validAccountField(field, shopper)"
                      :key="field.storeProp"
                      :class="field.class ? field.class : 'col-12 pt-0'"
                    >
                      <AccountFieldUI :field="field" :maskMap="maskMap" />
                    </v-col>
                  </v-row>
                  <div v-if="accountPanels.length === 1 && !steppersOutsideCards">
                    <PayContainer v-if="preSalePay" />
                    <CreditCheck v-else />

                    <v-col v-if="image" cols="12">
                      <v-img min-width="100%" :src="image" />
                    </v-col>
                  </div>
                  <!-- don't put continue button on last exp panel -->
                  <v-row v-if="accountPanels.length > 1 && i < accountPanels.length - 1" align="center" justify="center">
                    <v-btn
                      :disabled="!p.validated"
                      @click="makeActive(i + 1)"
                      :color="accountPanelContinueButtonColor"
                      :rounded="showRoundedContinueButton"
                    >
                      Continue</v-btn
                    >
                  </v-row>
                </v-form>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div v-if="accountPanels.length > 1 && !steppersOutsideCards">
            <PayContainer v-if="preSalePay" />
            <CreditCheck v-else />

            <v-col v-if="image" cols="12">
              <v-img min-width="100%" :src="image" />
            </v-col>
          </div>
        </v-form>
        <div v-if="steppersOutsideCards">
          <PayContainer v-if="preSalePay" />
          <CreditCheck v-else />
        </div>
      </v-col>
      <v-col class="hidden-sm-and-down col-md-5 col-sm-12">
        <Cart />
      </v-col>
    </v-row>
  </div>
</v-container>
