import { FullAddress, SalesType } from './../../Address/address'
//import { InputData } from 'quicktype-core'
import { getItemPriceAsNumber, getCatalogConfigItemValue, catalogRank } from './CatalogHelpers'
import { CatalogAddress, Tags } from './interface/CatalogAddress'
import {
  Item,
  BillingCode,
  Catalog,
  ChannelGroups,
  Config,
  CronDef,
  LOCMap,
  MarketingText,
  Package,
  Product,
  Tag,
  Fee,
  TagMap,
  TagWindow,
  RefData,
  Upgrade,
  Group,
  Equipment,
  CountedEquipmentGroup,
  taggedSheetNames,
  Promo,
  TaggedSheetName,
  SheetName,
  isTaggedSheetName,
  ItemType,
  Feature,
  FoundTagMap,
  BroadbandLabel
} from './interface/Catalog'
import * as XLSX from 'xlsx'
import * as cronParser from 'cron-parser'
import { Convert } from './CatalogConvert'
import _, { cloneDeep } from 'lodash'
import { NascentCatalog } from './interface/NascentCatalog'
import { SavedCatalog } from './interface/SavedCatalog'
import { MatchTypeFromString } from '../../../common/MatchType'
import { catalogVersionNumber } from '.'
import { validSalesTypes } from '../../Address'
import TSON, { IValidation } from 'typia'

class StrLogger {
  private logbuffer: string[] = []
  log(val: string) {
    this.logbuffer.push(val)
  }
  debug(val: string) {
    this.log('debug: ' + val)
  }
  warn(val: string) {
    this.log('warning: ' + val)
  }
  info(val: string) {
    this.log('info: ' + val)
  }
  error(val: string) {
    this.log('error: ' + val)
  }
  toString() {
    return this.logbuffer.join('\n')
  }
}

const dependencyFieldsWeCareAbout = ['Name', 'Product Type']

type LogString = string
export type { LogString }

type TagMapItem = {
  existingTag: string
  matchExpression: string
  matchType: string
  regexModifiers: string
  newTag: string
  newTagValue: string
}

function validateTagMap(tagMap: TagMapItem[], log: StrLogger): void {
  let rowNum = 1
  tagMap.forEach((el) => {
    rowNum++
    if (el.matchType === 'REGEX') {
      try {
        const re = new RegExp(el.matchExpression ?? '', el.regexModifiers ?? '')
        'test'.replace(re, el.newTagValue)
      } catch (error) {
        log.error(`Invalid TagMap Rexeg for "${el.matchExpression}" on row ${rowNum}: ${error}`)
      }
    }
  })
}

function trimTagMap(tagMap: TagMapItem[], log: StrLogger): void {
  tagMap.forEach((e) => {
    e.existingTag = e.existingTag?.trim() ?? ''
    e.matchExpression = e.matchExpression?.toString().trim() ?? ''
    e.matchType = e.matchType?.trim() ?? ''
    e.regexModifiers = e.regexModifiers?.trim() ?? ''
    e.newTag = e.newTag?.trim() ?? ''
    e.newTagValue = e.newTagValue?.toString().trim() ?? ''
    delete (e as any).matchTypes
  })
}

function getTagValue(tags: Tags, keyStr: string, log: StrLogger): string {
  const key = keyStr.trim()
  const varRE = /^\$\{(.*)\}$/
  const jsonRE = /^((?:\[.*\])|(?:\{.*\}))$/ // a JSON list or Object
  let varMatch = key.match(varRE)
  let jsonMatch = key.match(jsonRE)

  if (varMatch) {
    return evalTag(varMatch[1], tags, log)
  } else if (jsonMatch) {
    let jsonObject = key // Initialize to key string so that it is returned if the parsing fails
    try {
      jsonObject = JSON.parse(key)
    } catch (e) {
      log.error(`Error in TagMap assignment: Error parsing JSON '${key}'`)
    }
    return jsonObject
  } else {
    return key
  }
}

export const isProductPicker = (upgrade: Upgrade): boolean => {
  return typeof upgrade.Max === 'number' && upgrade.Max > 1
}

// parse tags similar to Typescript optional ? handling nested structures
// eg. address.streetNum?
// or shopper.custominfo?.anything.anything
// return '' if optional field is not there
function evalTag(t: string, tags: Tags, log: StrLogger): string {
  let arr = t.split('.')
  let obj
  while (arr.length > 0) {
    let hasQuestionMark = false
    if (arr[0].match(/\?$/)) {
      arr[0] = arr[0].replace(/\?$/, '')
      hasQuestionMark = true
    }
    const elem = arr.shift() as string // Can cast here because we tested the array length in while clause
    obj = obj ? obj[elem] : tags[elem]
    if (obj === undefined) {
      return obj
      // KWC code to error when question mark is not present
      // if (hasQuestionMark) {
      //   return ''
      // } else {
      //   throw new Error(`Cannot read property ${elem}`)
      // }
    }
  }
  switch (typeof obj) {
    case 'string':
      return obj
    case 'number':
      return obj.toString()
    case 'boolean':
      return obj ? 'true' : 'false'
    case 'object':
      return JSON.stringify(obj)
    default:
      return ''
    //throw new Error(`Unhandled Tag type ${JSON.stringify(obj)}`)
  }
}

type TagsAndTagmap = {
  newtags: Tags
  tagMap: FoundTagMap[]
}

function addTagMapItems(curTags: Tags, tagMap: TagMap[], log: StrLogger): TagsAndTagmap {
  const newtags = { ...curTags }
  const tagMapLog: FoundTagMap[] = []
  tagMap.forEach((e) => {
    const existingTag = e.existingTag
    const matchType = e.matchType
    const newTag = e.newTag
    const newTagString = e.newTagValue ?? ''
    const newTagValue = getTagValue(newtags, newTagString, log)
    if (existingTag !== undefined && matchType !== undefined && newTag !== undefined && newTagValue !== undefined) {
      const etStr = existingTag
      const separator = ':'
      const matchString = etStr.includes(separator) // need this ternary because
        ? etStr
            .split(separator)
            .map((v) => evalTag(v, newtags, log) ?? '')
            .join(separator) // this mapping by itself would convert undefined tags to '' ... breaking case UNDEFINED
        : evalTag(etStr, newtags, log)
      const hydratedE = {
        ...e,
        newTagActual: newTagValue,
        existingTagValues: matchString,
        newTagsOriginalVal: newtags[newTag],
        success: false,
        itemType: undefined
      }
      switch (matchType.trim()) {
        case 'UNDEFINED':
          if (matchString === undefined) {
            hydratedE.success = true
            newtags[newTag] = newTagValue
          }
          break
        case '':
        case 'STRING':
          if (matchString === e.matchExpression) {
            hydratedE.success = true
            newtags[newTag] = newTagValue
          }
          break
        case 'BOOLEAN':
          if (matchString?.toUpperCase() === e.matchExpression?.toUpperCase()) {
            hydratedE.success = true
            newtags[newTag] = newTagValue
          }
          break
        case 'TAG':
          if (e.matchExpression !== undefined && matchString === newtags[e.matchExpression]) {
            hydratedE.success = true
            newtags[newTag] = newTagValue
          }
          break
        case 'REGEX':
          try {
            const re = new RegExp(e.matchExpression ?? '', e.regexModifiers ?? '')
            if (matchString && e.newTag && matchString.match(re)) {
              hydratedE.success = true
              newtags[newTag] = newTagValue
            }
            // eslint-disable-next-line no-empty
          } catch (error) {}
          break
        case 'REGEX_NOT':
          try {
            const re = new RegExp(e.matchExpression ?? '', e.regexModifiers ?? '')
            if (matchString && e.newTag && !matchString.match(re)) {
              hydratedE.success = true
              newtags[newTag] = newTagValue
            }
            // eslint-disable-next-line no-empty
          } catch (error) {}
          break
        case 'REGSUB':
          try {
            const re = new RegExp(e.matchExpression ?? '', e.regexModifiers ?? '')
            if (matchString && e.newTag && matchString.match(re)) {
              hydratedE.success = true
              newtags[newTag] = matchString.replace(re, newTagValue)
            }
            // eslint-disable-next-line no-empty
          } catch (error) {}
          break
        case 'NUMERIC':
          try {
            const exprRegex = /^\s*(=|!=|<=?|>=?|)\s*(\S+)\s*$/
            const match = e.matchExpression?.match(exprRegex)
            const lhs = parseFloat(matchString)
            if (!match || Number.isNaN(lhs)) break
            const operator = match[1]
            let rhs = parseFloat(match[2])
            if (Number.isNaN(rhs)) {
              rhs = parseFloat(evalTag(match[2], newtags, log))
            }
            if (Number.isNaN(rhs)) break
            let result = false
            switch (operator) {
              case '=':
              case '':
                result = lhs === rhs
                break
              case '<':
                result = lhs < rhs
                break
              case '<=':
                result = lhs <= rhs
                break
              case '>':
                result = lhs > rhs
                break
              case '>=':
                result = lhs >= rhs
                break
              case '!=':
                result = lhs !== rhs
                break
              default:
                break
            }
            if (result) {
              hydratedE.success = true
              newtags[newTag] = newTagValue
            }
          } catch (err) {}
          break
        default: //skip if not expected type
      }
      tagMapLog.push(hydratedE)
    }
  })
  return { newtags, tagMap: tagMapLog }
}

function cleanWBSheet(wb: XLSX.WorkBook, sheetName: SheetName, log: StrLogger): any[] {
  let sht = XLSX.utils.sheet_to_json(wb.Sheets[sheetName])
  return cleanSheet(sht, sheetName, log)
}

function cleanSheet(sht: any[], sheetName: SheetName, log: StrLogger): any[] {
  sht = trimSheet(sht, sheetName, log) // trim all strings
  sht = removeBlankRowsFromSheet(sht, sheetName, log) // trim all strings
  if (isTaggedSheetName(sheetName)) {
    sht = removeUnNamedRowsFromSheet(sht, sheetName, log)
  }
  return sht.map((row) => Object.assign({}, row)) // do assign to get rid of __rowNum__ in rows
}

export async function convertXlsxToCatalog(data: Buffer): Promise<[NascentCatalog | null, LogString]> {
  const wb = XLSX.read(data)
  const log = new StrLogger()

  const sheets = {
    pkgs: cleanWBSheet(wb, 'Packages', log),
    products: cleanWBSheet(wb, 'Products', log),
    promos: cleanWBSheet(wb, 'Promos', log),
    fees: cleanWBSheet(wb, 'Fees', log),
    equips: cleanWBSheet(wb, 'Equipment', log),
    upgrades: cleanWBSheet(wb, 'Upgrades', log),
    channelGroups: pivotRowsToColumns(cleanWBSheet(wb, 'Channel Groups', log)),
    refData: pivotRowsToColumns(cleanWBSheet(wb, 'Reference Data', log)),
    locMap: cleanWBSheet(wb, 'Location Mapping', log),
    config: cleanWBSheet(wb, 'Config', log),
    marketingText: cleanWBSheet(wb, 'Marketing Text', log),
    groups: cleanWBSheet(wb, 'Groups', log),
    billingCodes: cleanWBSheet(wb, 'Billing Codes', log),
    tags: cleanWBSheet(wb, 'Tags', log),
    tagMap: cleanWBSheet(wb, 'TagMap', log),
    tagWindows: cleanWBSheet(wb, 'TagWindows', log) as any[] | undefined,
    features: cleanWBSheet(wb, 'Features', log),
    broadbandLabels: cleanWBSheet(wb, 'BroadbandLabels', log)
  }

  // add itemType to each item in the arrays
  addItemType(sheets.pkgs, 'Package')
  addItemType(sheets.products, 'Product')
  addItemType(sheets.promos, 'Promo')
  addItemType(sheets.fees, 'Fee')
  addItemType(sheets.equips, 'Equipment')
  addItemType(sheets.upgrades, 'Upgrade')
  //sheets.channelGroups['itemType'] = 'ChannelGroups' // different because the pivot above
  //sheets.refData['itemType'] = 'ReferenceData' // different because the pivot above
  addItemType(sheets.locMap, 'LocationMapping')
  addItemType(sheets.config, 'Config')
  addItemType(sheets.marketingText, 'MarketingText')
  addItemType(sheets.groups, 'Group')
  addItemType(sheets.billingCodes, 'BillingCode')
  addItemType(sheets.tags, 'Tag')
  addItemType(sheets.tagMap, 'TagMap')
  addItemType(sheets.features, 'Feature')
  addItemType(sheets.broadbandLabels, 'BroadbandLabel')
  if (sheets.tagWindows !== undefined) addItemType(sheets.tagWindows, 'TagWindow')

  trimTagMap(sheets.tagMap as TagMapItem[], log)
  validateTagMap(sheets.tagMap as TagMapItem[], log)

  // format prices to be 2 digit
  formatPrices(sheets.pkgs, 'Packages', log)
  formatPrices(sheets.promos, 'Promos', log)
  formatPrices(sheets.fees, 'Fees', log)
  formatPrices(sheets.equips, 'Equipment', log)
  formatPrices(sheets.upgrades, 'Upgrades', log)

  // trim spaces off values
  trimColumn(sheets.locMap, 'Franchise Name')

  // convert csv fields into arrays
  for (const sheet of Object.values(sheets)) {
    if (sheet instanceof Array) {
      convertCSVFieldToArrays(sheet, 'Dependencies')
      convertCSVFieldToArrays(sheet, 'Locations')
      convertCSVFieldToArrays(sheet, 'Fees')
      convertCSVFieldToArrays(sheet, 'CalculatedPrices')
      convertCSVFieldToArrays(sheet, 'Billing Codes')
      convertCSVFieldToArrays(sheet, 'Included Equipment')
      convertCSVFieldToArrays(sheet, 'Included Upgrades')
      convertCSVFieldToArrays(sheet, 'Required Equipment')
      convertCSVFieldToArrays(sheet, 'Required Upgrades')
      convertCSVFieldToArrays(sheet, 'Excluded Equipment')
      convertCSVFieldToArrays(sheet, 'Excluded Upgrades')
      convertCSVFieldToArrays(sheet, 'Upgrades')
      convertCSVFieldToArrays(sheet, 'Tooltip Image')
      convertCSVFieldToArrays(sheet, 'Features')
      // convertCSVFieldToArrays(sheet,'Config Value');
      convertNumFieldToString(sheet, 'Name')
      convertNumFieldToString(sheet, 'Display Name')
      convertNumFieldToString(sheet, 'Description')
      convertNumFieldToString(sheet, 'Billing Code')
      convertFieldToBoolean(sheet, 'expandable')
      convertFieldToBoolean(sheet, 'PriceIntoParent')
      convertFieldToBoolean(sheet, 'View')
    }
  }

  if (sheets.tagWindows) {
    sheets.tagWindows = convertTagWindowDefinitions(sheets.tagWindows as TagWindow[], log)
  }

  const pcat: NascentCatalog = {
    kind: 'nascent',
    version: 4,
    Packages: <Package[]>sheets.pkgs,
    products: <Product[]>sheets.products,
    promos: <Promo[]>sheets.promos,
    fees: <Fee[]>sheets.fees,
    equipment: <Equipment[]>sheets.equips,
    upgrades: <Upgrade[]>sheets.upgrades,
    ChannelGroups: <ChannelGroups>sheets.channelGroups,
    RefData: <RefData>sheets.refData,
    LocMap: <LOCMap[]>sheets.locMap, // CAST GAK KWC
    Config: <Config[]>sheets.config,
    MarketingText: <MarketingText[]>sheets.marketingText,
    Groups: <Group[]>sheets.groups,
    billingCodes: <BillingCode[]>sheets.billingCodes,
    tags: <Tag[]>sheets.tags,
    tagMap: <TagMap[]>sheets.tagMap,
    tagWindows: <TagWindow[]>sheets.tagWindows,
    features: <Feature[]>sheets.features,
    broadbandLabels: <BroadbandLabel[]>sheets.broadbandLabels
  }

  // Need prevent NPEs later on
  const cat = _hydrateCatalog(pcat, log)

  const performance = {
    now: () => new Date().getTime()
  }

  try {
    const t1 = performance.now()
    const validatedCatalog: IValidation<Catalog> = TSON.validate<Catalog>(cat)
    if (!validatedCatalog.success) {
      validatedCatalog.errors.forEach((e) => {
        log.log(`validation error: path=${e.path} expected=${e.expected}, actual=${e.value}`)
      })
      throw new Error(`TSON validation failed: ${cat}`)
    }
    const t2 = performance.now()
    log.log(`validating catalog with TSON took ${t2 - t1} ms`)
    log.log('Success!')
    return [pcat, log.toString()]
  } catch (e) {
    log.log(`Error! : ${e}`)
    return [null, log.toString()]
  }
}

export function itemDisplayName(item: Item): string {
  if (item === undefined) return ''
  return item['Display Name'] ?? item.Name ?? ''
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const nullCheck = (v: any): boolean => v !== null
export const undefinedCheck = (v: any): boolean => v !== undefined
export const qtyCheck = (v: Item): boolean => (v.qty ? v.qty > 0 : true)

export function hydrateCatalog(cat: SavedCatalog, log?: StrLogger): Catalog {
  let hc: Catalog
  hc = hydrateCatalogWithoutPackageUpgrades(cat, log)
  insertAllPackageUpgrades(hc)
  // removeInvalidPackages(hc)
  return hc
}

function removeInvalidPackages(cat: Catalog) {
  cat.Packages = cat.Packages.filter((p) => p.Products && p.Products.length > 0)
}

export function hydrateCatalogWithoutPackageUpgrades(cat: SavedCatalog, log?: StrLogger): Catalog {
  let hc: Catalog
  if (cat.kind === 'nascent') {
    hc = _hydrateCatalog(cat, log)
  } else {
    hc = cat as Catalog
  }
  return hc
}

function _hydrateCatalog(cat: NascentCatalog, log: StrLogger = new StrLogger()): Catalog {
  const pcat = cloneDeep(cat)
  if (pcat.products) setBlankColumnToEmptyArray(pcat.products, ['Upgrades'])

  injectFeesPricesAndBillingCodes('Equipment', pcat.equipment, pcat, log)
  pcat.equipment = buildCountedEquipmentGroups(pcat.equipment as any[])
  // insert Equip and Upgrades
  if (pcat.equipment && pcat.products) insertIfMatch('Equipment', pcat.products, pcat.equipment, 'Product Type')
  //insertIfMatch('Upgrades', sheets.products, sheets.upgrades, 'Product Type');
  // Insert global upgrades - ie, put upgrades on packages where product type is blank on upgrades tab
  if (pcat.Packages && pcat.upgrades) insertIfMatch('Upgrades', pcat.Packages, pcat.upgrades, 'Product Type')

  // Swap out references for full objects from other sheets.
  //  need to perform them in order of lowest hierarchy to highest
  injectFeesPricesAndBillingCodes('Fees', pcat.fees, pcat, log)
  injectFeesPricesAndBillingCodes('Upgrades', pcat.upgrades, pcat, log)
  injectFeesPricesAndBillingCodes('Promos', pcat.promos, pcat, log)
  injectFeesPricesAndBillingCodes('Products', pcat.products, pcat, log)
  injectFeesPricesAndBillingCodes('Packages', pcat.Packages, pcat, log)
  replaceCSVNamesWithObjectArrays('Products', 'Groups', pcat.products, 'Name', pcat.Groups, log)
  replaceCSVNamesWithObjectArrays('Products', 'Upgrades', pcat.products, 'Name', pcat.upgrades, log)
  replaceCSVNamesWithObjectArrays('Packages', 'Products', pcat.Packages, 'Name', pcat.products, log)
  replaceCSVNamesWithObjectArrays('Packages', 'Promos', pcat.Packages, 'Name', pcat.promos, log)
  replaceCSVNamesWithObjectArrays('Packages', 'Features', pcat.Packages, 'Name', pcat.features, log)
  replaceCSVNamesWithObjectArrays('Packages', 'BroadbandLabel', pcat.Packages, 'Name', pcat.broadbandLabels, log)
  replaceCSVNamesWithObjectArrays('Products', 'Features', pcat.products, 'Name', pcat.features, log)
  replaceCSVNamesWithObjectArrays('Equipment', 'Features', pcat.equipment, 'Name', pcat.features, log)
  replaceCSVNamesWithObjectArrays('Upgrades', 'Features', pcat.upgrades, 'Name', pcat.features, log)
  replaceCSVNamesWithObjectArrays('Fees', 'Features', pcat.fees, 'Name', pcat.features, log)
  replaceCSVNamesWithObjectArrays('Billing Codes', 'Features', pcat.billingCodes, 'Name', pcat.features, log)
  replaceCSVNamesWithObjectArrays('Features', 'Features', pcat.features, 'Name', pcat.features, log)
  // TODO: FEATURES add others here

  // put the product types on the package for use by ui/agent
  addLobsToPackages(pcat.Packages)

  // Somewhere we're getting references which is causing issues when removing things
  // and they're disappearing everywhere. For now just marshal/unmarshal it to fix
  // KWC not sure if this is still needed, but leaving it for now
  //
  // Mapping because we are hitting string length limits when doing it all at once.
  pcat.Packages = pcat.Packages.map((pkg) => JSON.parse(JSON.stringify(pkg)))

  // Check to see if anything in the catalog has a dependency and remove it if
  // those dependencies aren't met
  checkDependencies(pcat.Packages)

  // Set flags for any included equipment and services
  setIncludesAndRequireds(pcat.Packages)

  pcat.version = catalogVersionNumber

  const hcat: Catalog = { ...(<Catalog>(<unknown>pcat)), kind: 'hydrated' }
  return hcat
}

function addItemType(items: any[], itemType: ItemType) {
  items?.forEach((i) => (i['itemType'] = itemType))
}

function trimSheet(items: any[], sheetName: string, log: StrLogger): any[] {
  // string trim items in place
  let rowNum = 1
  items?.forEach((row) => {
    rowNum += 1
    for (const key in row) {
      if (typeof row[key] === 'string') {
        //const t = row[key].trim()
        const t = row[key].replace(/^\s+$/, '')
        if (t !== row[key]) {
          //log.warn(`"${key}" value "${row[key]}" contains leading/trailing whitespace in sheet "${sheetName}" row ${rowNum}`)
          log.warn(`"${key}" value "${row[key]}" is spaces in sheet "${sheetName}" row ${rowNum}`)
          row[key] = t
        }
      }
    }
  })
  return items
}

function removeBlankRowsFromSheet(items: any[], sheetName: string, log: StrLogger): any[] {
  // Keep any record that has ANY non-strings or a "truthy" string (not space or "")
  // return items.filter((i) => Object.values(i).find((val) => typeof val !== 'string' || val))
  let rowNum = 1
  const retItems: any[] = []
  items?.forEach((row) => {
    rowNum += 1
    if (Object.values(row).find((val) => typeof val !== 'string' || val)) {
      retItems.push(row)
    } else {
      log.warn(`Blank row found in sheet "${sheetName}" row ${rowNum}`)
    }
  })
  return retItems
}

function removeUnNamedRowsFromSheet(items: any[], sheetName: TaggedSheetName, log: StrLogger): any[] {
  // Keep any record that has ANY non-strings or a "truthy" string (not space or "")
  // return items.filter((i) => Object.values(i).find((val) => typeof val !== 'string' || val))
  let rowNum = 1
  const retItems: any[] = []
  items?.forEach((row) => {
    rowNum += 1
    if (row['Name']) {
      retItems.push(row)
    } else {
      log.warn(`Un-named row found in sheet "${sheetName}" row ${rowNum}`)
    }
  })
  return retItems
}

function removeBlankRows(items: any[]): any[] {
  // string trim items in place
  const reducedItems = []
  items?.forEach((row) => {
    for (const key in row) {
      if (typeof row[key] === 'string') {
        row[key] = row[key].trim()
      }
    }
  })
  return items
}

function trimColumn(items: any[], column: string) {
  items?.forEach((i) => {
    if (i[column]) i[column] = i[column].trim()
  })
}

function deleteColumn(items: any[], column: string) {
  items?.forEach((i) => {
    if (i[column]) delete i[column]
  })
}

function setBlankColumnToEmptyArray(sheet: any[], columnNames: string[]) {
  for (const row of sheet) {
    for (const columnName of columnNames) {
      if (!row[columnName]) {
        row[columnName] = []
      }
    }
  }
}

function pivotRowsToColumns(obj: any[]) {
  const retVal: any = {}
  for (const o of obj) {
    for (const key in o) {
      if (retVal[key] == undefined) {
        retVal[key] = []
      }
      retVal[key].push(o[key])
    }
  }
  return retVal
}

function formatPrices(obj: any[], sheetName: string, logger: StrLogger) {
  let rowNum = 1
  for (const o of obj) {
    rowNum += 1
    for (const key in o) {
      if (key === 'Monthly Price' || key === 'OTC' || key === 'Price') {
        if (typeof o[key] === 'string') {
          logger.warn(`Non-numeric price in sheet "${sheetName}", row "${rowNum}", column "${key}", value "${o[key]}"`)
        } else {
          if (o[key]) {
            try {
              o[key] = Number(o[key].toFixed(2))
            } catch (e) {
              // KWC need to add this back once we figure out logger
              logger.warn(`Error parsing price in sheet "${sheetName}", row "${rowNum}", column "${key}", value "${o[key]}" : ${e}`)
            }
          }
        }
      }
    }
  }
}

function addLobsToPackages(pkgs: Package[]) {
  for (const pkg of pkgs) {
    if (pkg.Products) {
      const products = pkg.Products
      const lobs = products.map((p) => p['Product Type']).filter((p) => p) as string[] // filter out undefined and empty strings
      pkg.lobs = [...new Set(lobs)] // get unique LOBS
    }
  }
}

function buildCountedEquipmentGroups(equipment: Equipment[] | undefined) {
  if (equipment === undefined) return undefined
  const neq: Equipment[] = []
  equipment
    .filter((i) => i.ProgressionParent === undefined)
    .forEach((e) => {
      // foreach possible equipment group
      const si = equipment.filter((i) => i.ProgressionParent === e.Name) // get all sub-equipment for this group
      const ne = si.length ? ({ ...e, itemType: 'CountedEquipmentGroup', subItems: si } as CountedEquipmentGroup) : e // if there are sub-equipment, convert to CountedEquipmentGroup
      neq.push(ne)
    })
  return neq
}

function injectFeesPricesAndBillingCodes(
  destSheetName: SheetName, // Only used for logging
  destSheet: any[] | null | undefined,
  pcat: NascentCatalog,
  log: StrLogger
) {
  replaceCSVNamesWithObjectArrays(destSheetName, 'Fees', destSheet, 'Name', pcat.fees, log)
  replaceCSVNamesWithObjectArrays(destSheetName, 'CalculatedPrice', destSheet, 'Name', pcat.fees, log)
  replaceCSVNamesWithObjectArrays(destSheetName, 'Billing Codes', destSheet, 'Name', pcat.billingCodes, log)
}

function replaceCSVNamesWithObjectArrays(
  destSheetName: SheetName, // Only used for logging
  destFieldName: string, // Also the source SheetName
  destSheet: any[] | null | undefined,
  srcKeyField: string, // should always be 'Name' for now
  srcSheet: any[] | null | undefined,
  logger: StrLogger
) {
  if (!destSheet) return

  let rowNum = 1
  for (const d of destSheet) {
    rowNum += 1
    const dest: string | string[] = d[destFieldName]
    if (dest != undefined) {
      const names = dest instanceof Array ? dest : dest.split(',').map((s) => s.trim())
      const objs: any[] = []
      const missing: any[] = []
      for (const n of names) {
        let found = false
        if (srcSheet) {
          for (const o of srcSheet) {
            //logger.debug('o=' + JSON.stringify(o));
            if (o[srcKeyField] === n.trim()) {
              objs.push(o)
              found = true
            }
          }
        }

        if (!found && n) {
          missing.push([srcKeyField, n])
          logger.warn(`Reference "${n}" in column "${destFieldName}" on row ${rowNum} of sheet "${destSheetName}" not found`)
        }
      }

      d[destFieldName] = objs
    }
  }
}

function insertIfMatch(name: string, dest: any[], src: any[], matchName: string) {
  for (const d of dest) {
    d[name] = []
    for (const s of src) {
      if (s[matchName] === d[matchName]) {
        d[name].push(s)
      }
    }
  }
}

// TODO: this code is broken (it should ALWAYS create an array even for '')
// and propably the reason that setBlankColumnToEmptyArray exists
function convertCSVFieldToArrays(sheet: { [key: string]: any }[], fieldName: string, delimiter = ',') {
  for (const row of sheet) {
    if (row[fieldName] === 0 || row[fieldName] || row[fieldName] === '') {
      row[fieldName] = splitAndTrim(row[fieldName], delimiter)
    }
  }
}

function removeBlankFields(sheet: any[]) {
  for (const row of sheet) {
    for (const [k, v] of Object.entries(row)) {
      if (typeof v === 'string' && v.match(/^\s*$/)) {
        row[k] = undefined
      }
    }
  }
}

function convertNumFieldToString(sheet: { [key: string]: any }[], fieldName: string) {
  for (const row of sheet) {
    if (row[fieldName] && typeof row[fieldName] !== 'string') {
      row[fieldName] = row[fieldName].toString()
    }
  }
}

function toBoolean(a: any): boolean {
  switch (typeof a) {
    case 'string':
      return a.match(/^(true|1|yes|y|on)$/i) != null
    case 'boolean':
      return a
    case 'number':
      return a === 1
    default:
      return false
  }
}

function convertFieldToBoolean(sheet: { [key: string]: any }[], fieldName: string) {
  for (const row of sheet) {
    if (row[fieldName]) {
      row[fieldName] = toBoolean(row[fieldName])
    }
  }
}

function getConfigValue(config: { [key: string]: any }, key: string, defaultValue: string): string {
  return config[key] ? config[key] : defaultValue
}

function assertNever(x: never, msg: string): never {
  throw new Error(msg)
}

export function filterAndFixUpCatalog(
  clientId: string,
  hydratedAddressFilter: CatalogAddress,
  catalog: SavedCatalog
): SavedCatalog {
  const newcat = filterCatalog(clientId, hydratedAddressFilter, catalog)
  return newcat
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function filterCatalog(clientId: string, hydratedAddressFilter: CatalogAddress, catalog: SavedCatalog): SavedCatalog {
  let cat = cloneDeep(catalog)
  // switch (cat.kind) {
  //   case undefined: {
  //     cat = { ...cat, kind: 'hydrated' }
  //     break
  //   }
  //   case 'nascent': {
  //     cat = _hydrateCatalog(cat)
  //     break
  //   }
  //   case 'hydrated': {
  //     // do nothing
  //     break
  //   }
  //   default: {
  //     assertNever(cat, `Unknown Catalog kind in Database`)
  //   }
  // }
  _filterCatalog(clientId, hydratedAddressFilter, cat, cat)

  _filterPackages(catalog, cat)

  return cat
}

function _filterPackages(ogCatalog: SavedCatalog, filteredCatalog: SavedCatalog) {
  const filterPackagesWithEmptyProducts = getCatalogConfigItemValue(
    filteredCatalog.Config,
    'filterPackagesWithEmptyProducts'
  )?.match(/^\s*(?:true|t|yes|Y)\s*$/i)?.length
    ? true
    : false
  const filterPackagesWithFilteredIncludedItems = getCatalogConfigItemValue(
    filteredCatalog.Config,
    'filterPackagesWithFilteredIncludedItems'
  )?.match(/^\s*(?:true|t|yes|Y)\s*$/i)?.length
    ? true
    : false
  const filterPackagesWithFilteredRequiredItems = getCatalogConfigItemValue(
    filteredCatalog.Config,
    'filterPackagesWithFilteredRequiredItems'
  )?.match(/^\s*(?:true|t|yes|Y)\s*$/i)?.length
    ? true
    : false

  //loop through all packages in original catalog  and remove any from cat where the product is not in the new package
  for (const pkg of ogCatalog.Packages) {
    let removePkg = false
    const newpkg = filteredCatalog.Packages.find((p) => p.Name === pkg.Name)
    if (newpkg !== undefined) {
      // check if the package has any products that have been filtered out by tags
      if (filterPackagesWithEmptyProducts && hasEmptyProducts(newpkg.Products, filteredCatalog.products)) {
        removePkg = true
      }
      // check if the package has any included items that have been filtered out by tags
      if (filterPackagesWithFilteredIncludedItems) {
        if (
          hasFilteredItems(newpkg?.['Included Equipment'], filteredCatalog.equipment) ||
          hasFilteredItems(newpkg?.['Included Upgrades'], filteredCatalog.upgrades)
        ) {
          removePkg = true
        }
      }
      // check if the package has any required items that have been filtered out by tags
      if (filterPackagesWithFilteredRequiredItems) {
        if (
          hasFilteredItems(newpkg?.['Required Equipment'], filteredCatalog.equipment) ||
          hasFilteredItems(newpkg?.['Required Upgrades'], filteredCatalog.upgrades)
        ) {
          removePkg = true
        }
      }
      if (removePkg) {
        filteredCatalog.Packages = filteredCatalog.Packages.filter((p) => p.Name !== pkg.Name)
      }
    }
  }
}

function hasEmptyProducts(productNames?: string, allProducts?: Product[]): boolean {
  if (!productNames || !allProducts) return true
  const splitNames = productNames.split(', ')
  return splitNames.some((p) => !allProducts.find((ap) => ap.Name === p))
}

function hasFilteredItems(items?: string[], allItems?: Item[]): boolean {
  if (!items) return false
  if (!allItems) return true
  return items.some((i) => !allItems.find((ai) => ai.Name === i))
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function setDefaultConfigItem(catalog: SavedCatalog, name: string, value: any): void {
  if (!catalog.Config.find((i) => i.Name === name)) {
    catalog.Config.push({
      Name: name,
      itemType: 'Config',
      'Config Value': value
    })
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function setConfigItem(catalog: SavedCatalog, name: string, value: any): void {
  const item = catalog.Config.find((i) => i.Name === name)
  if (item) {
    item['Config Value'] = value
  } else {
    catalog.Config.push({
      Name: name,
      itemType: 'Config',
      'Config Value': value
    })
  }
}

// filter catalog recursively in place
function _filterCatalog(clientId: string, hydratedAddressFilter: CatalogAddress, catalog: SavedCatalog, node: any): void {
  for (const nodeName in node) {
    let item = node[nodeName]
    const sheetPkColumnName = getConfigValue(catalog.Config, `${nodeName}.sheetPkColumnName`, 'Name')
    if (item instanceof Array) {
      // filterSection not needed once all Catalogs stop using locationMapping sheet
      // filterSection(clientId, hydratedAddressFilter, item, catalog, sheetPkColumnName)
      node[nodeName] = item = filterSectionUsingTags(clientId, hydratedAddressFilter, item, catalog, sheetPkColumnName)
    }
    if (item instanceof Object) {
      // recurse if item is a regular Object or an Array (instanceof Object is 'true' for Arrays)
      _filterCatalog(clientId, hydratedAddressFilter, catalog, item)
    }
  }
}

// hydrateAddress tags with Catalog tagMap data
export function hydrateCatalogAddress(
  clientId: string,
  address: CatalogAddress,
  catalog: SavedCatalog,
  log?: StrLogger
): CatalogAddress {
  if (!log) {
    log = new StrLogger()
  }

  let newAddress: FullAddress = JSON.parse(JSON.stringify(address))
  if (newAddress === undefined) newAddress = {}
  if (newAddress.tags === undefined) newAddress.tags = {}

  // KWC this section can be commented out now that NO catalog use the locationMapping sheet
  // const locationTypes: string[] = catalog.RefData['Location Types'] ?? [] // list of location types
  // const tagKeys: string[] = catalog.RefData['TagKeys'] ?? [] // list of tag keys
  // const tagLocs: string[] = locationTypes.filter((l) => tagKeys.includes(l)) // intersection of the locs and tagkeys
  // const locationMapping = catalog.LocMap
  // tagLocs.forEach((type) => {
  //   const value = mapToValue(address, locationMapping, type)
  //   if (value) {
  //     newAddress.tags[type] = value
  //   }
  // })

  newAddress.tags['env'] = process.env.ENV ?? 'local'
  const { newtags, tagMap } = addTagMapItems(newAddress.tags, catalog.tagMap, log)
  newAddress.tags = newtags
  newAddress.tagMap = tagMap

  if (!newAddress.tags.brand) newAddress.tags.brand = clientId
  //newAddress.tags.when = new Date().toISOString()
  // set the address matchType based on the tags in the Catalog, in case the catalog overrides it
  const mt = newAddress.tags.matchType
  if (mt) {
    try {
      const catalogMatchType = MatchTypeFromString(mt)
      if (catalogMatchType) {
        newAddress.matchType = catalogMatchType
        if (address?.tags?.matchType !== catalogMatchType) {
          newAddress.tags.addressMatchTypeOverridenBy = ['catalog']
        }
      }
    } catch (error) {
      log.warn(`Invalid MatchType "${mt}"`)
    }
  }
  let st: any = newAddress.tags.salesType
  if (typeof st === 'string') {
    st = st.toLowerCase()
  }
  if (!st || typeof st !== 'string' || validSalesTypes[st as SalesType] === undefined) {
    st = newAddress.serviceable === 'Y' ? 'serviceable' : 'none'
    log.error(
      `SalesType tag is not a string for client ${clientId}, address='${newAddress.inputAddress}: ${st} ... setting salesType to '${st}'`
    )
  }
  newAddress.salesType = st as SalesType
  newAddress.tags.salesType = st
  return newAddress as CatalogAddress
}

function filterSection(clientId: string, address: CatalogAddress, section: any[], catalog: Catalog, sheetPkColumnName: string) {
  // Note: this assumes spreadsheet order of location types is from least to most specific, thus the reverse
  const loctypes = catalog.RefData['Location Types'] ?? []
  const reversedLocationTypes: string[] = [...loctypes].reverse()
  const locationMapping = catalog.LocMap

  for (let i = 0; i < section.length; i++) {
    const s = section[i]
    const type = s['Location Type']
    const locations = s['Locations']
    if (type && locations.length > 0) {
      for (const locType of reversedLocationTypes) {
        if (type == locType) {
          //logger.debug("type=" + type + " locType=" + locType);
          // determine what db column to get data from
          let ovalue: string | undefined = undefined
          /* */
          const value =
            type === 'Zip'
              ? address.zip
              : type === 'State'
              ? address.state
              : type === 'Company'
              ? address.company
              : type === 'Division'
              ? address.division
              : type === 'Franchise'
              ? address.franchise
              : type === 'Market'
              ? mapToValue(address, locationMapping, type)
              : type === 'DMA'
              ? mapToValue(address, locationMapping, type)
              : undefined

          if (type == 'Zip') {
            ovalue = address.zip
          } else if (type == 'State') {
            ovalue = address.state
          }
          // else if(type == 'Company/Division/Franchise') { // TODO: implement
          //     value = address.state;
          // }
          // else if(type == 'Region') { // TODO: implement
          //     value = address.state;
          // }
          else if (type == 'Market' || type === 'DMA') {
            ovalue = mapToValue(address, locationMapping, type) // TODO: uncomment once we have
          }
          /* */
          if (value !== ovalue) {
            //console.log(`value=${value}, ovalue=${ovalue}`)
          }

          // determine if it matches and if not then remove
          if (!value || !locations.includes(value)) {
            //logger.debug("removing: i=" + i + " name=" + s[sheetPkColumnName]);
            section.splice(i, 1)
            i-- // need to decrement i since we removed an item
            break
          } else if (value) {
            // remove global values if they are overridden by specific ones
            for (let j = 0; j < section.length; j++) {
              const s2 = section[j]
              if (s[sheetPkColumnName] == s2[sheetPkColumnName] && s2['Locations'] == undefined) {
                section.splice(j, 1)
                // decrement both counters since we're in both loops
                if (i < j) {
                  i--
                }
                j--
              }
            }
          }
        }
      }
    }
  }
}

const lowerFirstLetter = ([first, ...rest]: string) => first.toLowerCase() + rest.join('')

// Compare function for tags
//
// replace '*' with tab character so that we can just split on '.' and
// then use simple string compare for each element of the tag
// this makes 'a.<anystring>.c' a better match than 'a.*.c'
//
function compareTags(a: string, b: string): number {
  a = a.replace(/\*/g, '\t') // replace * with Tab 0x0B (next lowest precedence)
  b = b.replace(/\*/g, '\t') // replace * with Tab 0x0B (next lowest precedence)
  let al = a.split('.')
  let bl = b.split('.')
  for (let i = 0; i < al.length; ++i) {
    if (bl[i] > al[i]) {
      return 1
    }
    if (al[i] > bl[i]) {
      return -1
    }
  }
  return 0
}

function canonicalizeTag(tag: string, blank: string): string {
  if (tag === undefined || tag.trim() === '') return blank
  const tl = tag.split('.')
  const bl = blank.split('.')
  const len = bl.length
  for (let i = 0; i < len; ++i) {
    if (i === tl.length) tl[i] = bl[i]
  }
  return tl.join('.')
}

function matchKey(catalog: SavedCatalog, key: string, addressedKeyField: string, matchKeyField: string) {
  if (key === 'when') {
    const twkey = matchKeyField
    const twval = catalog.tagWindows?.find((w) => w.Name === twkey)
    if (twval) {
      const durationMillis = (typeof twval.duration !== 'number' ? 5 : twval.duration) * 1000
      const start = new Date()
      const end = new Date()
      end.setTime(start.getTime() + durationMillis)
      // console.log(`Start = ${start}`)
      // console.log(`End = ${end}`)

      for (let c of twval.window) {
        // console.log('Cron expr: ', c.cron)
        let interval = cronParser.parseExpression(c.cron, {
          startDate: start,
          endDate: end,
          iterator: true
        })
        // if any interval matches, return true
        if (interval.hasNext()) {
          // while (interval.hasNext()) {
          //   console.log('Cron time: ', interval.next().value.toString())
          // }
          return true
        }
      }
      return false
    } else {
      return false
    }
  } else {
    return addressedKeyField === matchKeyField
  }
}

function matchTag(catalog: SavedCatalog, tagKeys: string[], addressKey: string, matchTag: string): boolean {
  let addressKeyArr = addressKey.split('.')
  let matchTagArr = matchTag.split('.')
  if (addressKeyArr.length !== matchTagArr.length) return false
  for (let i = 0; i < addressKeyArr.length; ++i) {
    //if (matchTagArr[i] !== '*' && addressKeyArr[i] !== matchTagArr[i]) return false
    if (matchTagArr[i] !== '*' && !matchKey(catalog, tagKeys[i], addressKeyArr[i], matchTagArr[i])) return false
  }
  return true
}

function filterSectionUsingTags(
  clientId: string,
  address: CatalogAddress,
  section: any[],
  catalog: SavedCatalog,
  sheetPkColumnName: string
): any[] {
  const tagKeys: string[] = (catalog.RefData['TagKeys'] as string[]).map((s) => lowerFirstLetter(s))

  //console.log(address)
  //console.log(address.tags)
  // if no tagKeys, then bail since we won't know how to filter (maybe this is an old catalog without filters anyway)
  if (!tagKeys || tagKeys.length == 0 || !section.find((i) => i.Tag)) {
    return section
  }

  // build proper length blank tag string ie *.*.*.* for 4 tags
  const blankItemTag = tagKeys.map((x) => '*').join('.')

  // Build tag list using tagKeys in address
  const addressTagValues = tagKeys.map((t) => address.tags?.[t] ?? '*')
  const addressTagToMatch = addressTagValues.join('.')

  const result = [] as any[]
  const sec = [...section]

  // canonicalize all of the tags
  for (const rec of sec) {
    rec.Tag = canonicalizeTag(rec.Tag, blankItemTag)
  }
  // sort records by Name and tag in order so that * is lower than actual values
  sec.sort((a, b) => compareTags(`${a[sheetPkColumnName]}.${a.Tag}`, `${b[sheetPkColumnName]}.${b.Tag}`)) // Add Names to Tags for comparison

  let names = new Set()
  sec.forEach((rec) => names.add(rec[sheetPkColumnName]))
  names.forEach((name) => {
    // find the best match
    let match = sec.filter((r) => r[sheetPkColumnName] === name).find((r) => matchTag(catalog, tagKeys, addressTagToMatch, r.Tag))
    if (match) {
      result.push(match)
    }
  })
  return result
}

function getAsteriskedTagValues(originalTagValues: string[], asteriskCount: number): string {
  // clone tagValues because we don't want to change original reference
  const tagValues = [...originalTagValues]

  // get asterisk mask - convert to binary string
  const mask = asteriskCount.toString(2).padStart(tagValues.length, '0')
  //logger.debug(`asteriskCount=${asteriskCount} mask=${mask}`);

  for (let i = 0; i < tagValues.length; i++) {
    if (mask.charAt(i) === '1') {
      tagValues[i] = '*'
    }
  }
  return tagValues.join('.')
}

function mapToValue(address: CatalogAddress, locationMapping: LOCMap[] | undefined, mapToType: string): string | undefined {
  //logger.debug(`mapToValue(): started`);

  let value: any = undefined

  if (locationMapping) {
    for (const mappingRow of locationMapping) {
      if (mappingRow['To Location'] != undefined && mapToType == mappingRow['Map To Location Type']) {
        value = mapCoDivFran(address, mappingRow)
        if (!value) {
          value = mapZip(address, mappingRow)
        }
      }
      if (value) {
        break
      }
    }
  }

  return value
}

function mapCoDivFran(address: CatalogAddress, mappingRow: LOCMap): string | undefined {
  //logger.debug(`mapCoDivFran(): started`);

  if (
    address.company &&
    mappingRow.Company &&
    address.company == mappingRow.Company.toString() &&
    address.division &&
    mappingRow.Division &&
    address.division == mappingRow.Division.toString() &&
    address.franchise &&
    mappingRow.Franchise &&
    address.franchise == mappingRow.Franchise.toString()
  ) {
    return mappingRow['To Location']
  }
  return undefined
}

function mapZip(address: CatalogAddress, mappingRow: LOCMap): string | undefined {
  //logger.debug(`mapZip): started`);
  return address.zip && mappingRow.Zip && address.zip == mappingRow.Zip.toString() ? mappingRow['To Location'] : undefined
}

function splitAndTrim(field: string | number, delimiter: string): string[] {
  const strfld = field.toString().trim()
  const rarr = strfld ? strfld.split(delimiter).map((f) => f.trim()) : [] // split on delimiter and trim each field if not empty
  return rarr
}

export function insertAllPackageUpgrades(catalog: SavedCatalog): void {
  //updatePackagePrices(catalog.Packages)
  insertPackageUpgrades(catalog, catalog.Packages, 'Internet', 'Speed')
  insertPackageUpgrades(catalog, catalog.Packages, 'TV', 'Num Channels')
  insertPackageUpgrades(catalog, catalog.Packages, 'Phone', 'Speed')
}

function insertPackageUpgrades(catalog: SavedCatalog, pkgs: Package[], productType: string, comparisonField: string): void {
  //let p = pkgs[0];
  for (const p of pkgs)
    if (p.Products) {
      // get names of all the products in this package
      const productNames = p.Products.map((item) => item.Name)

      // get the name of the product that we're looking for upgrades for
      const nameToIgnore = nameOfProductContainingProductType(p.Products, productType)

      if (nameToIgnore) {
        // filter the list of product names in this package not including the one we're looking for upgrades for
        const filteredNames = productNames.filter((item) => item !== nameToIgnore)

        // find other packages that have the exact same products && same included equip/upgrades
        const matches = getMatchingPackages(
          catalog,
          pkgs,
          filteredNames,
          productType,
          p['Included Equipment'] ?? [],
          p['Included Upgrades'] ?? []
        )

        const rankAB = (a: Package, b: Package) => {
          const aRank = catalogRank(a.Rank)
          const bRank = catalogRank(b.Rank)
          if (aRank === bRank) {
            return (
              (getNumericProductField(a, productType, comparisonField) ?? Number.MIN_VALUE) >
              (getNumericProductField(b, productType, comparisonField) ?? Number.MIN_VALUE)
            )
          } else {
            return aRank < bRank
          }
        }

        // sort descending
        matches.sort((a, b) => (rankAB(a, b) ? -1 : 1))

        // build items to insert
        const itemsToInsert: any[] = []
        for (const m of matches) {
          const mValue = getProductField(m, productType, comparisonField)
          if (getProductField(m, productType, comparisonField) > getProductField(p, productType, comparisonField)) {
            const upgradeText = displayNameOfProductContainingProductType(m.Products, productType)
            const subtitle = catalog.MarketingText.find((mt) => mt.Name === upgradeText)?.['Subtitle[upgrade]']
            itemsToInsert.push({
              'Product Type': productType,
              Subcategory: 'Package Upgrade',
              Value: mValue,
              Package: m.Name,
              Price: upgradePrice(m, p),
              UpgradeText: upgradeText,
              Subtitle: subtitle
            })
          }
        }

        if (itemsToInsert.length > 0) {
          const productToInsertInto = getProductContainingProductType(p.Products, productType)
          if (productToInsertInto) {
            productToInsertInto.Upgrades ??= []
            productToInsertInto.Upgrades.push(...itemsToInsert)
          }
        }
      }
    }

  // calc upgrade price
  function upgradePrice(m: Package, p: Package) {
    const upper = getItemPriceAsNumber(m, 'Monthly Price')
    const lower = getItemPriceAsNumber(p, 'Monthly Price')
    return Number(upper - lower).toFixed(2)
  }
}

function containsAllProductNames(products: Product[], names: string[]): boolean {
  for (const n of names) {
    let found = false
    for (const p of products) {
      if (p.Name === n) {
        found = true
        break
      }
    }
    if (!found) {
      return false
    }
  }
  return true
}

function getProductContainingProductType(products: Product[], productType: string): Product | undefined {
  for (const p of products) {
    if (p['Product Type'] === productType) {
      //logger.debug('getProductContainingProductType():' + p.Name);
      return p
    }
  }
  //logger.debug('getProductContainingProductType(): not found');
  return undefined
}

function nameOfProductContainingProductType(products: Product[], productType: string): string | undefined {
  const p = getProductContainingProductType(products, productType)
  return p?.Name ?? undefined
}

function displayNameOfProductContainingProductType(products: Product[], productType: string): string | undefined {
  const p = getProductContainingProductType(products, productType)
  return p?.['Display Name']?.toString() ?? p?.Name ?? undefined
}

function includesProductType(products: Product[], productType: string): boolean {
  const p = getProductContainingProductType(products, productType)
  return p ? true : false
}

function getNumericProductField(pkg: Package, productType: string, field: string): number | undefined {
  let n = Number(getProductField(pkg, productType, field))
  return isNaN(n) ? undefined : n
}

function getProductField(pkg: Package, productType: string, field: string): number | string {
  const p = getProductContainingProductType(pkg.Products, productType)
  return p ? (p as any)[field] : 0
}

function getMatchingPackages(
  catalog: SavedCatalog,
  pkgs: Package[],
  productNames: string[],
  productType: string,
  inclEq: string[],
  inclUp: string[]
): Package[] {
  const doLegacyExactPackageUpgradeMatch =
    getCatalogConfigItemValue(catalog.Config, 'doLegacyExactPackageUpgradeMatch')?.match(/^\s*(?:true|t|yes|Y)\s*$/i) !== null ??
    true

  const packageUpgradeIgnoreEquipment = getCatalogConfigItemValue(catalog.Config, 'packageUpgradeIgnoreEquipment')?.match(
    /^\s*(?:true|t|yes|Y)\s*$/i
  )?.length
    ? true
    : false

  const packageUpgradeIgnoreUpgrades = getCatalogConfigItemValue(catalog.Config, 'packageUpgradeIgnoreUpgrades')?.match(
    /^\s*(?:true|t|yes|Y)\s*$/i
  )?.length
    ? true
    : false
  const retVal: Package[] = []

  for (const pkg of pkgs) {
    if (
      pkg.Products.length == productNames.length + 1 && // we removed an item so names is always one less
      containsAllProductNames(pkg.Products, productNames) &&
      includesProductType(pkg.Products, productType) &&
      (packageUpgradeIgnoreEquipment || packageHasIncludes(inclEq, pkg['Included Equipment'], doLegacyExactPackageUpgradeMatch)) &&
      (packageUpgradeIgnoreUpgrades || packageHasIncludes(inclUp, pkg['Included Upgrades'], doLegacyExactPackageUpgradeMatch))
    ) {
      retVal.push(pkg)
    }
  }
  return retVal
}

function arrayContainsArray(superset: Array<any>, subset: Array<any>) {
  return subset.length ? subset.every((value) => superset.indexOf(value) >= 0) : true
}

function packageHasIncludes(upgradeNeeds?: Array<string>, packageHas?: Array<string>, doExactMatch = true): boolean {
  upgradeNeeds ??= [] // Array of needed equipment or upgrades
  packageHas ??= [] // Array of what the package has
  const ret = doExactMatch
    ? JSON.stringify(upgradeNeeds.sort()) === JSON.stringify(packageHas.sort())
    : arrayContainsArray(packageHas, upgradeNeeds)
  return ret
}

function checkDependencies(pkgs: any[]) {
  const pkgsWithDeps = pkgs.filter(hasDependencies)
  for (let i = 0; i < pkgsWithDeps.length; i++) {
    const p = pkgsWithDeps[i]
    const allDependencyFields: string[] = []
    getDependencyFields(allDependencyFields, p)
    //logger.debug(`${p.Name} = ${JSON.stringify(allDependencyFields)}`);
    removeWhereDependencyNotMet(allDependencyFields, pkgsWithDeps, i)
  }
}

function hasDependencies(obj: any): boolean {
  return obj ? JSON.stringify(obj).indexOf('Dependencies') != -1 : false
}

function getDependencyFields(all: string[], current: any) {
  if (current instanceof Object) {
    for (const key in current) {
      if (Array.isArray(current[key])) {
        for (const o of current[key]) {
          getDependencyFields(all, o)
        }
      } else if (dependencyFieldsWeCareAbout.includes(key)) {
        // add if not already there
        if (!all.includes(current[key])) {
          all.push(current[key])
        }
      }
    }
  }
}

function removeWhereDependencyNotMet(allDependencyFields: string[], parent: any[], index: number): boolean {
  const current = parent[index]
  let anyRemoved = false
  if (current instanceof Object) {
    for (const key in current) {
      if (key == 'Dependencies') {
        if (!hasAllDependencies(allDependencyFields, current[key])) {
          parent.splice(index, 1)
          anyRemoved = true
          //logger.debug(`Not all dependencies met for: ${current.Name}`);
          //logger.debug(JSON.stringify(parent));
        }
      } else if (Array.isArray(current[key])) {
        for (let i = 0; i < current[key].length; i++) {
          if (removeWhereDependencyNotMet(allDependencyFields, current[key], i)) {
            i--
          }
        }
      }
    }
  }
  return anyRemoved
}

function hasAllDependencies(allDependencyFields: string[], required: string[]): boolean {
  for (const r of required) {
    const orRequirements = r.split('|')
    let any = false
    for (const o of orRequirements) {
      if (allDependencyFields.includes(o.trim())) {
        any = true
        break
      }
    }
    if (!any) {
      return false
    }
  }
  return true
}

function setIncludesAndRequireds(pkgs: any[]) {
  const columns: string[] = [
    'Included Equipment',
    'Included Upgrades',
    'Required Equipment',
    'Required Upgrades',
    'Excluded Equipment',
    'Excluded Upgrades'
  ]

  for (const c of columns) {
    for (const pkg of pkgs) {
      // gather all incl/req because they might span product types
      const incls = pkg[c] ? pkg[c] : []
      for (const prod of pkg.Products) {
        const prodIncls = prod[c] ? prod[c] : []
        incls.push(...prodIncls)

        // if(prod.Groups) {
        //     for(let group of prod.Groups) {
        //         const groupIncls =  group[c] ? group[c] : [];
        //         incls.push(...groupIncls);
        //     }
        // }
      }

      // loop through again and set flags
      if (c.indexOf('Upgrades') != -1) {
        setIncludedsRequiredsAndExcludeds(pkg.Upgrades, incls, c)
      }
      for (const prod of pkg.Products) {
        const items = c.indexOf('Equipment') != -1 ? prod.Equipment : prod.Upgrades
        setIncludedsRequiredsAndExcludeds(items, incls, c)
      }
    }
  }
}

function setIncludedsRequiredsAndExcludeds(items: any[], incls: any, c: string) {
  for (const [i, item] of items.entries()) {
    for (const incl of incls) {
      const [name, qty] = incl.split(':')
      if (item.Name == name) {
        if (c.indexOf('Included') != -1) {
          items[i] = { ...item, included: qty ? qty : '1' }
          // deep clone because items might not be dereferenced
          // item.included = qty ? qty : '1'
        } else if (c.indexOf('Excluded') != -1) {
          items[i] = { ...item, excluded: qty ? qty : '1' }
          // deep clone because items might not be dereferenced
          // item.excluded = qty ? qty : '1'
        } else {
          items[i] = { ...item, required: qty ? qty : '1' }
          // deep clone because items might not be dereferenced
          // item.required = qty ? qty : '1'
        }
      }
    }
  }
}

function convertTagWindowDefinitions(tw_sheet: any[], log: StrLogger): TagWindow[] | undefined {
  const result = [] as TagWindow[]
  tw_sheet.forEach((rec) => {
    const name = rec.Name
    if (name === undefined) {
      log.error('TagWindows entry missing "Name" field')
      return
    }
    if (rec?.itemType !== 'TagWindow') {
      log.error(`Invalid itemType on TagWindows entry "${name}"`)
      return
    }
    const def: string = rec.window?.toString().trim()
    if (!def) {
      log.error(`No "window" definition for TagWindows entry "${name}"`)
      return
    }
    const windowItem = rec as TagWindow
    try {
      let crons: CronDef[] | undefined = undefined
      if (def.match(/^[\[{"]/)) {
        // It starts with a JSON character
        const o = JSON.parse(def)
        switch (typeof o) {
          case 'string':
            crons = [{ cron: o }] // make string one cron object and fall thru to object case
            break
          case 'object':
            crons = Array.isArray(o) ? o : [o] // make it an array if its not
            break
          default:
            log.error(`Invalid definition "${JSON.stringify(o)}" for TagWindows entry "${name}"`)
            return
        }
      } else {
        // process as a sequence of '|' separated CRONs
        crons = def.split(/\s*\|\s*/).map((c) => {
          return { cron: c.trim() }
        })
      }

      crons = validateCronArray(name, crons, log)
      if (crons !== undefined) {
        windowItem.window = crons
        result.push(windowItem)
      } else {
        return // continue processing the remainder of the tagWindow items
      }
    } catch (e) {
      log.error(`Failed to parse TagWindows entry "${name}": ${e}`)
      return
    }
    return
  })
  return result
}

function validateCronArray(name: string, crons: any[], log: StrLogger): CronDef[] | undefined {
  for (let c of crons) {
    if (typeof c?.cron === 'string') {
      try {
        cronParser.parseExpression(c.cron)
      } catch (e) {
        log.error(`Invalid cron expression "${c.cron}" for "${name}" in TagWindows`)
        return undefined
      }
    } else {
      log.error(`Invalid interval definition "${JSON.stringify(c)}" for "${name}" in TagWindows`)
      return undefined
    }
  }

  return crons as CronDef[]
}
