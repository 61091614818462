
<div>
  <div v-if="!hasChildren && !hasPromos">
    <v-row>
      <!-- todo: check about tooltip boolean for included/required -->
      <v-col class="py-0" :cols="cartLineItemKeyCols ? cartLineItemKeyCols : 8"
        ><span v-html="uiMacroParser(shopper, parent.name ?? '') + quantity"></span><TooltipDebug :item="item" /><Tooltip
          :payload="{
            Name: item.Name,
            included: item.included ? true : false,
            required: item.required ? true : false,
            itemType: item.itemType
          }"
        />
      </v-col>
      <v-col class="py-0 text-right" :cols="cartLineItemValueCols ? cartLineItemValueCols : 4">
        <span
          v-html="
            uiMacroParser(shopper, parent?.PricePrefix ?? '') + ' ' + parent.qty && parseInt(parent?.qty?.toString() ?? '0') > 1
              ? coreCurrency(parent.calculatedPrice) + (duration ? `<span class='duration'>${duration}</span>` : '') ??
                coreCurrency(parent.price) + +(duration ? `<span class='duration'>${duration}</span>` : '')
              : coreCurrency(parent.price) + (duration ? `<span class='duration'>${duration}</span>` : '')
          "
        ></span>
      </v-col>
    </v-row>
    <!-- <div v-if="promoProgressions.length > 1">
      <v-row v-for="(promo, i) in promoProgressions" class="pl-2" :key="`${parent.name}-promo-${i}`">
        <v-col cols="2"></v-col>
        <v-col class="py-0 promo-text" cols="8">{{ promo }} </v-col>
      </v-row>
    </div> -->
  </div>
  <v-expansion-panels v-else flat v-model="panel">
    <v-expansion-panel class="ma-0 pa-0" id="cartLine">
      <v-expansion-panel-header hide-actions class="pa-0" style="font-size: 1rem">
        <v-row class="ma-0">
          <v-col class="pa-0" :cols="cartLineItemKeyCols ? cartLineItemKeyCols : 9"
            ><span v-html="uiMacroParser(shopper, parent.name ?? '') + quantity"></span><TooltipDebug :item="item" /><Tooltip
              :payload="{
                Name: item.Name,
                included: item.included ? true : false,
                required: item.required ? true : false,
                itemType: item.itemType
              }"
            />
          </v-col>
          <v-col :class="parentPrice" class="pa-0 text-right" :cols="cartLineItemValueCols ? cartLineItemValueCols : 3">
            <span
              ><v-icon v-if="panel == 0" class="pb-1 mr-1" small color="primary">{{ mdiMinusCircle }}</v-icon>
              <v-icon class="pb-1 mr-1" v-else small color="primary">{{ mdiPlusCircle }}</v-icon
              ><span
                v-html="
                  uiMacroParser(shopper, parent?.PricePrefix ?? '') + ' ' + parent.qty &&
                  parseInt(parent?.qty?.toString() ?? '0') > 1
                    ? coreCurrency(parent.calculatedPrice) + (duration ? `<span class='duration'>${duration}</span>` : '') ??
                      coreCurrency(parent.price) + +(duration ? `<span class='duration'>${duration}</span>` : '')
                    : coreCurrency(parent.price) + (duration ? `<span class='duration'>${duration}</span>` : '')
                "
              ></span
            ></span>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div v-if="hasPromos">
          <v-row v-for="(promo, i) in promoProgressions" :key="`${parent.name}-promo-${i}`">
            <v-col cols="1"></v-col>
            <v-col class="py-0 pl-2 promo-text" cols="11">{{ promo }} </v-col>
          </v-row>
        </div>
        <div v-if="hasChildren" class="ma-0 pa-0">
          <div v-for="child in children" :key="child.Name" class="ma-0 pa-0">
            <CartLineItem :item="child" :priceType="priceType" :expanded="expanded" />
          </div>
        </div>
        <!-- <v-row v-for="child in children" :key="child.name">
          <v-col class="py-0 pl-2" cols="8">{{ child.name }} </v-col>

          <v-col class="py-0 text-right" cols="4">
            <span>{{ child.price | coreCurrency }}</span>
          </v-col>
        </v-row> -->
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</div>
